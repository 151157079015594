import React from "react";
import "./omicron.css";
import o1 from "./images1/o1.jpg";
import o2 from "./images1/o2.jpg";
import o3 from "./images1/o3.jpg";
import o4 from "./images1/o4.jpg";

function Omicron() {
  return (
    <div className="omain-container">
      <h3 className="oh">OMICRON</h3>
      <div className="ocard-container">
        <div className="ocard">
          <img src={o1} alt="example" className="ocard-img" />
        </div>
        <div className="ocard">
          <div className="ocard-text">
            <p className="odata" style={{ textIndent: '40px' ,textAlign:'justify'  }}><br></br>
            The webinar was organized on February 19, 2022 (Saturday) in the evening. It was held on the virtual platform "ZOOM". The agenda of the webinar was to "stop the transmission and crush the pandemic". Globally, the emergence of the new Corona virus variant, Omicron, has once again aroused fear among people.<br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="ocard-container">
        <div className="ocard">
          <div className="ocard-text">
            <p className="odata" style={{ textIndent: '40px' ,textAlign:'justify'  }}><br></br>
            Our main objective of this webinar was to cascade awareness and disseminate wisdom on the comprehensive understanding of the risk and prevention of Omicron to lead a safer and healthier life. 
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="ocard">
          <img src={o2} alt="example" className="ocard-img" />
        </div>
      </div>

      <div className="ocard-container">
        <div className="ocard">
          <img src={o3} alt="example" className="ocard-img" />
        </div>
        <div className="ocard">
          <div className="ocard-text">
            <p className="odata" style={{ textIndent: '40px' ,textAlign:'justify'  }}><br></br>
            Our eminent speaker for the event was Dr. Lalit Gupta (an anesthesiologist at Lok Nayak Hospital). In the webinar, he gave us information related to Omicron, such as what we understand by it, how it is affecting our body, the signs, and symptoms of Omicron, and how we can prevent and cure it.
              <br></br>
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="ocard-container">
        <div className="ocard">
          <div className="ocard-text">
            <p className="odata" style={{ textIndent: '40px' ,textAlign:'justify'  }}>
                <br></br>
            He also told us about vaccinations, whether they are safe, and why people need to get them. The session was a great success, as the participants understood the importance of vaccination and became aware of it.

              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="ocard">
          <img src={o4} alt="example" className="ocard-img" />
        </div>
      </div>
    </div>
  );
}

export default Omicron;
