import React from "react";
import Anuradha_Sehrawat from "./images1/Anuradha_Sehrawat.jpg";
import Dr_Neera from "./images1/Dr_Neera.jpg";
import Shubham_Garg from "./images1/Shubham_Garg.jpg";
import "./legalSupport.css";
import Contactus from "./contactfrm";

const Card = ({ imageSrc, title, buttonText, linkTo, desc }) => {
  return (
    <div className="pjcard-cont">
      <img src={imageSrc} alt={title} className="pjcard-image-cont" />
      <h2 className="pjcard-title-cont">{title}</h2>
      <h6 className="desc-cont">{desc}</h6>
    </div>
  );
};

const LegalSupport = () => {
  return (
    <>
      <div class="card-cont w-100">
        <h1 class="card-title-cont">LEGAL SUPPORT</h1>
        <div class="card-body-cont ">
          Waves believes in empowering women in every aspect of life, like
          protecting women from domestic violence, dowry prohibition, maternity
          benefits, the right to privacy, and political rights. If, you want any
          help in regard to any of the above-mentioned issues, we will provide
          you with the best services.
        </div>

        <div className="pjcards-container-cont">
          <h1>Support Team Members</h1>
          <div className="pjrow-cont">
            <Card
              imageSrc={Anuradha_Sehrawat}
              title="Anuradha Sehrawat"
              desc="(HR Professional)"
            />
            <Card
              imageSrc={Dr_Neera}
              title="Dr. Neera Singh"
              desc="(Social Worker)"
            />
            <Card
              imageSrc={Shubham_Garg}
              title="Shubham Garg"
              desc="(Advocate)"
            />
          </div>
        </div>
      </div>
      <Contactus />
    </>
  );
};

export default LegalSupport;
