import React from "react";
import { Link } from "react-router-dom";
import "./projects.css";
import EngMain from "./images1/EngMain.jpg";
import yoga1 from "./images1/Yoga1.jpg";
import yoga2 from "./images1/Yoga2.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="pjcard">
      <img src={imageSrc} alt={title} className="pjcard-image" />
      <h2 className="pjcard-title">{title}</h2>
      <Link to={linkTo} className="pjcard-link">
        <button className="pjcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Projects = () => {
  return (
    <div className="pjcards-container">
      <h1>PROJECTS</h1>
      <div className="pjrow">
        <Card
          imageSrc={EngMain}
          title="ENGLISH"
          buttonText="Read More"
          linkTo="/english"
        />
        <Card
          imageSrc={yoga1}
          title="YOGA 1"
          buttonText="Read More"
          linkTo="/yoga1"
        />
        <Card
          imageSrc={yoga2}
          title="YOGA 2"
          buttonText="Read More"
          linkTo="/yoga2"
        />
      </div>
    </div>
  );
};

export default Projects;
