import React from "react";
import "./address.css";
import Contactus from "./contactfrm";

const Address = () => {
  return (
    <>
      <div className="card-cont ">
        <h1 className="card-title-cont col d-flex justify-content-center ">
          ADDRESS
        </h1>
        <div className="card-body-cont col d-flex justify-content-center ">
          9/350A, G.no.: 11, Lalita Park, Laxmi Nagar, New Delhi-110092<br></br>
          Contact no: +91-9667715007, +91-9911179893 <br></br> Email Id:
          waves111@waveswomen.org, waveswomenempower@gmail.com
        </div>

        <div className="map col d-flex justify-content-center ">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9586231863677!2d77.2709672752414!3d28.631002175665582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcb2e56ccd99%3A0xa6136022b82c133e!2sGali%20No.%209%2C%20Lalita%20Park%2C%20Laxmi%20Nagar%2C%20Delhi%2C%20110092!5e0!3m2!1sen!2sin!4v1683272848582!5m2!1sen!2sin"
            width={1300}
            height={500}
            style={{ border: 0 }}
            allowFullScreen=""
            title="Google maps"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <Contactus />
      </div>
    </>
  );
};

export default Address;
