import React from "react";
import { Link } from "react-router-dom";
import "./ourwork.css";
import Ahmain from "./images1/Ahmain.jpg";
import MD from "./images1/MD.jpg";
import EngMain from "./images1/EngMain.jpg";
import Omain from "./images1/OMain.jpg";
import StressM from "./images1/StressM.jpg";
import YWM from "./images1/YWM.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="owrkcard">
      <img src={imageSrc} alt={title} className="owrkcard-image" />
      <h2 className="owrkcard-title">{title}</h2>
      <Link to={linkTo} className="owrkcard-link">
        <button className="owrkcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const OurWork = () => {
  return (
    <div className="owrkcards-container">
      <h1>OUR WORK</h1>
      <div className="owrkrow">
        <Card
          imageSrc={Ahmain}
          title="AIDS/HIV"
          buttonText="Read More"
          linkTo="/aids"
        />
        <Card
          imageSrc={MD}
          title="MEDITATION"
          buttonText="Read More"
          linkTo="/meditation"
        />
        <Card
          imageSrc={EngMain}
          title="ENGLISH"
          buttonText="Read More"
          linkTo="/english"
        />
      </div>
      <div className="owrkrow">
        <Card
          imageSrc={YWM}
          title="YOGA"
          buttonText="Read More"
          linkTo="/yoga"
        />
        <Card
          imageSrc={Omain}
          title="OMICRON"
          buttonText="Read More"
          linkTo="/omicron"
        />
        <Card
          imageSrc={StressM}
          title="STRESS MANAGEMENT"
          buttonText="Read More"
          linkTo="/stress"
        />
      </div>
    </div>
  );
};

export default OurWork;
