import React from "react";
import "./ycmp.css";
import yc1 from "./images1/yc1.jpg";
import yc2 from "./images1/yc2.jpg";
import yc3 from "./images1/yc3.jpg";

function Ycmp() {
  return (
    <div className="ycmain-container">
      <h3 className="yh">YOGA CAMP</h3>
      <div className="yccard-container">
        <div className="mcard">
          <img src={yc1} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              The workshop was organised on September 4, 2021 (Saturday) in the
              morning. It was held in Dwarka. The agenda of the event was "YOGA"
              ( योग स्वयं की, स्वयं के माध्यम से, स्वयं की यात्रा है| ) for
              women, where the yoga mentor was YOGACHARYA SHALLU. <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Since she is an incredible teacher, she taught different types of
              asanas along with their benefits so that the students got insights
              and reasons for doing the asanas. For example, she covered
              breathing and pranayama (Anulom Vilom, Kapalabhati ), joint
              movements (neck movements, shoulder rotation), meditative
              practices, etc.<br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc2} alt="example" className="yccard-img" />
        </div>
      </div>

      <div className="yccard-container">
        <div className="mcard">
          {/* <img src={yc3} alt="example" className="yccard-img" /> */}
          <img
            src={yc3}
            alt="example"
            className="yccard-img"
            style={{ height: "45rem", width: "40rem" }}
          />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Awareness was raised by explaining how yoga increases flexibility,
              strength, and balance. Fights depression and anxiety, promotes
              mind-body connections, etc. Yoga exercises will not only
              strengthen our immunity system amid the ongoing COVID-19 pandemic
              but also improve our lung capacity, which will aid our breathing.{" "}
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ycmp;
