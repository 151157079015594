import React from "react";
import "./ycmp.css";
import yc1 from "./swasthamatrutva/img4 (5).jpg";
import yc2 from "./swasthamatrutva/20230702_142552.jpg";
import yc3 from "./swasthamatrutva/img4 (2).jpg";
import yc4 from "./swasthamatrutva/20230702_142337.jpg";
import yc5 from "./swasthamatrutva/20230702_134546.jpg";
import yc6 from "./swasthamatrutva/20230702_142548.jpg";


export default function SwasthaMatrutva() {
  return (
    <div className="ycmain-container">
      <h3 className="yh">Swastha Matrutva</h3>
      <div className="yccard-container">
        <div className="mcard">
          <img src={yc1} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             "Unleash Your Pen, Transform the Narrative"

              The Open Writing Competition was conducted in the month of May, which gave the the participants an opportunity where they fearlessly unleashed their pens and transformed the narrative.  <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             
             The Competition had a profound impact by providing a platform for women to share their unique stories and perspectives. It empowered participants to embrace their voices and unleash their creativity and sparked inspiration and broadened perspectives for both writers and readers. 
             <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc2} alt="example" className="yccard-img" />
        </div>
      </div>

      <div className="yccard-container">
      <div className="yccard">
          <img src={yc3} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             We are thrilled to announce the winner of the competition, Varshitha V Reddy, her story has resonated deeply, capturing hearts and minds whose exceptional contributions has earned the prestigious Appreciation Certificate
             .<br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>


      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              We are immensely grateful to all the writers who took part in this empowering journey, breathing life into their stories and embracing the transformative power of storytelling. Your unique perspectives have enriched our collective understanding and reminded us of the boundless possibilities that lie within the written word. 
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc6} alt="example" className="yccard-img" />
        </div>
      </div>



      <div className="yccard-container">
      <div className="yccard">
          <img src={yc5} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
           Overall, the competition celebrated the importance of sharing the narratives,  the collective power of the written word to connect, inspire, and create a space for people where they can share their feelings. 
           <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              
          We have shared some of the stories on our Spotify podcast so that the feelings can get a voie to connect and inspire. 

        Stay connected with us for future events and competitions. {" "}
              <br></br>
              <br></br>
            </p>
          </div>    
        </div>
        <div className="mcard">
          {/* <img src={yc3} alt="example" className="yccard-img" /> */}
          <img
            src={yc4}
            alt="example"
            className="yccard-img"
            style={{ height: "45rem", width: "40rem" }}
          />
        </div>
      </div>
    </div>
  );
}

