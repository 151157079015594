import React from "react";
import "./ycmp.css";
import yc1 from "./graphial/Screenshot (968).png";
import yc2 from "./graphial/Screenshot (1014).png";
import yc3 from "./graphial/Screenshot (991).png";
import yc4 from "./graphial/Screenshot (1018).png";


export default function Graphalogy() {
  return (
    <div className="ycmain-container">
      <h3 className="yh">Graphalogy</h3>
      <div className="yccard-container">
        <div className="mcard">
          <img src={yc1} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             Our virtual workshop on "Handwriting and Signature Analysis," organized by Waves Women Empowerment, was a transformative event held on June 17th, 2023, via Google Meet. With esteemed guest speaker Ms. Neeru Gupta, a renowned graphology expert, the workshop aimed to empower participants by unlocking the secrets hidden within their handwriting.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Ms. Gupta's presentation emphasized the profound impact of handwriting analysis on understanding personality traits, emotions, and attitudes. By exploring the fundamental principles of graphology, participants gained valuable insights into analyzing handwriting characteristics and decoding their meanings. The workshop also highlighted the importance of crafting a signature that authentically represents one's true self while maintaining a professional image.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc2} alt="example" className="yccard-img" />
        </div>
      </div>

      <div className="yccard-container">
        <div className="mcard">
          {/* <img src={yc3} alt="example" className="yccard-img" /> */}
          <img
            src={yc3}
            alt="example"
            className="yccard-img"
            style={{ height: "45rem", width: "40rem" }}
          />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Moreover, the workshop addressed the significance of remedial writing in relieving stress, anxiety, and enhancing academic performance. Ms. Gupta shared effective techniques and exercises, empowering attendees to harness the power of writing for their well-being and academic success. Also, question-and-answer session facilitated interactive engagement, allowing participants to seek personalized guidance and deepen their understanding of graphology.
              {" "}
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>


      <div className="yccard-container">
        
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
          Overall, the workshop proved to be an enlightening and empowering experience. Participants gained knowledge and skills to interpret their own and others' handwriting, enabling them to enhance personal relationships, make informed decisions, and tap into their true potential. The workshop underscored the importance of graphology as a powerful tool for self-discovery, personal growth, and professional success. 
The high engagement and the wonderful feedbacks given by the participants after the session made the session a huge success. 
          <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="mcard">
          <img src={yc4} alt="example" className="yccard-img" />
        </div>
      </div>

    </div>  
   
  );
}
