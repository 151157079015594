import React from "react";
import "./english.css";
import Eng1 from "./images1/Eng1.jpg";
import Eng2 from "./images1/Eng2.jpg";
import Eng3 from "./images1/Eng3.jpg";

function English() {
  return (
    <div className="engmain-container">
      <h3 className="engh">ENGLISH CLASSES</h3>
      <div className="engcard-container">
        <div className="engcard">
          <img src={Eng1} alt="example" className="engcard-img" />
        </div>
        <div className="engcard">
          <div className="engcard-text" style={{ textIndent: "40px" }}>
            <p
              className="engdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              Waves Women Empowerment organised a three-month project to provide
              English classes to women through digital mediums at no cost. The
              vision was, all women and girls of any age should have access to
              quality education so that they can feel empowered and become aware
              of their rights.<br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="engcard-container">
        <div className="engcard">
          <div className="engcard-text">
            <p
              className="engdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              The project was designed for beginners, and the schedule was
              prepared by the mentor, Diya Narayan. She started from the
              introduction of the English language to the advanced concepts,
              keeping in mind that each lesson should be valuable for the
              students. Each day, the participation increased and the lessons
              got more advanced, making them aware of each important concept of
              the language.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="engcard">
          <img src={Eng2} alt="example" className="engcard-img" />
        </div>
      </div>

      <div className="engcard-container">
        <div className="engcard">
          <img src={Eng3} alt="example" className="engcard-img" />
        </div>
        <div className="engcard">
          <div className="engcard-text">
            <p
              className="engdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              This was the trust's very first project, which was exciting as
              well as providing a great learning experience. It was a positive
              experience for us, and we learned a lot from it. Finally, the
              student's reaction was positive, which encouraged us to continue
              on our road.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default English;
