import React from "react";
import Contactus from "./contactfrm";
import divashri from "./images1/divashri.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo, desc }) => {
  return (
    <div className="pjcard-cont mx-auto">
      <img src={imageSrc} alt={title} className="pjcard-image-cont" />
      <h2 className="pjcard-title-cont">{title}</h2>
      <h6 className="desc-cont ">{desc}</h6>
    </div>
  );
};

const Fitness = () => {
  return (
    <>
      <div class="card-cont w-100">
        <h1 class="card-title-cont">FITNESS</h1>
        <div class="card-body-cont text-center">
          For a healthy life and positive mindset, yoga is a crucial activity to
          adopt in daily life. We are providing a fitness coach that will help
          you with meditation, self-realisation, improved strength, anger
          management, and depression management. And along with this, the coach
          will provide confidence management classes too.
        </div>

        <div className="pjcards-container-cont">
          <h1>Support Member</h1>
          <div className="pjrow-cont">
            <Card
              imageSrc={divashri}
              title=" Divashri "
              desc="( Yoga Mentor)"
            />
          </div>
        </div>
      </div>
      <Contactus />
    </>
  );
};

export default Fitness;
