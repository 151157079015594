import React from "react";
import Contactus from "./contactfrm";

const Medical = () => {
  return (
    <>
      <div class="card-cont w-100">
        <h1 class="card-title-cont">MEDICAL</h1>
        <div class="card-body-cont ">
          Waves Women Empowerment aims the most at spreading awareness related
          to health, and we are offering help in the below mentioned areas from
          our very reputed doctor. HIV/AIDS and sexually transmitted diseases.
          Thyroid disease, diabetes, female cancer, pregnancy, iron deficiency
          in ladies, and obesity. Sex education and family planning.
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Doctors</th>
              <th>Designation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Dr. Ravi Kaushik</td>
              <td>
                Assistant Professor of Physiology Maulana Azad Medical College &
                Associated Hospitals
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Dr. Lalit Gupta</td>
              <td>
                ASSOCIATE PROFESSOR (Anaesthesia) in Maulana Azad Medical
                College & Associated Hospitals,
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Contactus />
    </>
  );
};

export default Medical;
