import React, { useEffect, useState } from "react";
import "./OurBlogs.css";
import blogsData from "./data/blogs";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ blogName, blogImage, blogText, handleNavigate }) => (
  <div
    className="blog"
    onClick={() => handleNavigate({ blogName, blogImage, blogText })}
  >
    <img src={blogImage} alt="blog" className="blogImage" />
    <span className="blogName">{blogName}</span>
    <p className="blogPrev">{blogText}</p>
    <div className="readMoreBtn">Keep Reading</div>
  </div>
);

const OurBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = (blog) => {
    navigate("/blog", { state: blog });
  };
  useEffect(() => {
    setBlogs(blogsData);
  }, []);
  return (
    <div className="primaryBox">
      <span className="sectionHeading">READ OUR BLOGS</span>
      <div className="blogsContainer">
        {blogs.map((blog, index) => (
          <BlogCard key={index} {...blog} handleNavigate={handleNavigate} />
        ))}
      </div>
    </div>
  );
};

export default OurBlogs;
