import React from "react";
import "./survey.css";
import srv1 from "./images1/srv1.jpg";
import srv2 from "./images1/srv2.jpg";
import EsrM from "./images1/EsrM.jpg";

function Survey() {
  return (
    <div className="srvmain-container">
      <h3 className="srvh">EMPOWERMENT SURVEY</h3>
      <div className="srvcard-container">
        <div className="srvcard">
          <img src={srv1} alt="example" className="srvcard-img" />
        </div>
        <div className="srvcard">
          <div className="srvcard-text">
            <p
              className="srvdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              Being a woman is a matter of pride but does every woman feel this
              pride? Does every woman consider herself empowered? Where do women
              see themselves in the society? It is important to know the answers
              to all these questions so that we can understand whether women
              still consider themselves less than anyone? <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="srvcard-container">
        <div className="srvcard">
          <div className="srvcard-text">
            <p
              className="srvdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              To get answers to all these questions, Waves Women Empowerment
              Trust conducted a survey among women . the purpose of this survey
              was to find out how empowered women consider themselves. This
              survey makes us all wonder why being empowered is still a dream
              for many women today?
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="srvcard">
          <img src={srv2} alt="example" className="srvcard-img" />
        </div>
      </div>

      <div className="srvcard-container">
        <div className="srvcard">
          <img src={EsrM} alt="example" className="srvcard-img" />
        </div>
        <div className="srvcard">
          <div className="srvcard-text">
            <p
              className="srvdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              Why even today such organizations are needed which fight for the
              rights of women? Women are definitely stronger than ever but are
              they feeling strong enough? Waves Women Empowerment Trust work is
              to make every woman realize their strength, which we are
              definitely doing. The task is certainly not easy but effort is
              necessary.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Survey;
