import React from "react";
import "./medistories.css";

function Medistories() {
  return (
    <div className="medistmain-container">
      <h3 className="medisth">STORIES OF CHANGE THROUGH MEDITATION WORKSHOP</h3>
      <div className="medistcard-container">
        <div className="medistcard">
          <div className="medistcard-text">
            <p
              className="medistdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ 20-year-old, Mansi enrolled in a meditation class. She
              discovered inner calm with regular practise, and her mental health
              considerably improved. The youngster experienced an increase in
              assurance and optimism, which had a good effect on many parts of
              her life and improved her general wellbeing. Since, she was facing
              a lot of anxiety issues at a very young age.
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medistories;
