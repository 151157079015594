import React from "react";
import "./download.css";
import perm from "./Allpdf/Permission DCGI.pdf";

const documents = [{ title: "Permission DCGI", url: perm }];

const CertList = () => {
  const openPdf = (doc) => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = "download.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };
  return (
    <div className="document-table">
      <h2
        className="table-heading"
        style={{ fontSize: "3rem", paddingTop: "1rem", paddingBottom: "1rem" }}
      >
        REGISTRATION CERTIFICATE
      </h2>
      <table>
        <thead>
          <tr>
            <th className="document-title-header">Document Title</th>
            <th className="download-header">Download</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="document-row">
              <td className="document-title">{doc.title}</td>
              <td className="document-download-cell">
                <buFtton
                  className="document-download-button"
                  onClick={() => openPdf(doc.url)}
                >
                  Download
                </buFtton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CertList;
