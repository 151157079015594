import React from "react";
import "./yoga2.css";

import yoga2 from "./images1/Yoga2.jpg";

import YG22 from "./images1/YG22.jpg";
import YG23 from "./images1/YG23.jpg";

function Yoga2() {
  return (
    <div className="yg2main-container">
      <h3 className="yg2h">YOGA II</h3>
      <div className="yg2card-container">
        <div className="yg2card">
          <img src={yoga2} alt="example" className="yg2card-img" />
        </div>
        <div className="yg2card">
          <div className="yg2card-text">
            <p
              className="yg2data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Under "Yoga for women" with the theme of "A way to improve inner
              strength and confidence." Waves Women Empowerment organised a
              one-month project from December 5, 2022 (Monday) to January 5,
              2023 (Thursday). in which our aim for the classes was to reach
              women and girls through digital mediums and to teach for free at
              no cost. Through this project, we aimed to generate awareness for
              yoga by informing people of its benefits and encouraging them to
              incorporate yoga into their daily routine.
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yg2card-container">
        <div className="yg2card">
          <div className="yg2card-text">
            <p
              className="yg2data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              This was our second edition of yoga classes. We wanted to end the
              year 2022 on a positive note for our community. By giving them
              healthy habits, we are very thankful that we achieved that goal
              since there was positive feedback and an increase in
              participation.
              <br></br>
              <br></br>
              The schedule for yoga was designed per week by the mentor itself
              to make it effective. In the first week, she introduced everyone
              to the yoga asanas of Surya Namaskar with a light warm-up. The
              second week was introduced by her with some additional basic
              asanas and their benefits, like Yaskitasana, Parvatasana,
              Bharadvaia’s Twist, etc.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yg2card">
          <img src={YG22} alt="example" className="yg2card-img" />
        </div>
      </div>

      <div className="yg2card-container">
        <div className="yg2card">
          <img src={YG23} alt="example" className="yg2card-img" />
        </div>
        <div className="yg2card">
          <div className="yg2card-text">
            <p
              className="yg2data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              The third week became more about teaching the advanced asanas like
              Chakrasana (Wheel Pose), Halasana, Hastapadangustasana, Anulom
              Vilom Pranayama, Utkatasana (Chair Pose), etc., along with
              relaxation meditation with positive affirmations, and the last
              week was for practising to get perfection.
              <br></br>
              <br></br>
              The students realised that practising yoga helps maintain better
              physical and mental health, energy levels, and self-esteem. It can
              also help with several medical conditions, since several studies
              have shown that yoga can lower blood pressure, and cholesterol and
              lower the risk of heart disease. Not only this, yoga activates the
              energy chakras and provides strength to deal with their
              physiological and psychological changes. It aids in optimising the
              performance of the body's organs, and it also balances the
              hormones, moods, and inner peace to attain a quality of life.
              <br></br>
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yoga2;
