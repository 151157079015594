import React from "react";
import "./drawing.css";
import draw1 from "./images1/draw1.jpg";
import draw2 from "./images1/draw2.jpg";

function Drawing() {
  return (
    <div className="dmain-container">
      <h3 className="dh">DRAWING COMPETITION</h3>
      <div className="dcard-container">
        <div className="dcard">
          <img src={draw1} alt="example" className="dcard-img" />
        </div>
        <div className="dcard">
          <div className="dcard-text">
            <p
              className="ddata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              "Express your feelings with art." Just with this thought, "Waves
              Women Empowerment Trust" organized a painting competition on the
              occasion of International Women's Day. The theme of the
              competition was "Women Empowerment," in which women and girls of
              all classes and ages participated with full enthusiasm and joy.
              <br></br>
              Through this competition, women beautifully and innovatively
              depicted the slogan of women's empowerment in their art. By doing
              so, we have tried to spread the message of self-confidence,
              self-esteem, and self-reliance to all women.
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="dcard-container">
        <div className="dcard">
          <div className="dcard-text">
            <p
              className="ddata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              We have tried to make women understand the need to put their own
              ambitions ahead of others.
              <br></br>
              nd not only this, but also a message that together we will create
              our own identity and make everyone feel proud. All the women who
              participated in the competition were given certificates, and the
              winners were given suitable prizes. Waves promise to continue to
              make all possible efforts for the welfare and development of
              women.
              <br></br>
            </p>
          </div>
        </div>
        <div className="dcard">
          <img src={draw2} alt="example" className="dcard-img" />
        </div>
      </div>
    </div>
  );
}

export default Drawing;
