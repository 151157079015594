import React, { useEffect, useState } from "react";
import "./Volunteer.css";
import data from "./data/volunteer.json";

const Volunteer = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [openPos, setOpenPos] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setOpenPos(data);
  }, []);
  return (
    <div className="box">
      <span className="pageHeading">Volunteer with Waves</span>
      <div className="applyBox">
        <div className="desc">
          If you're looking for an opportunity to make a meaningful contribution
          to your community, HERE WE ARE! As a non-profit organization, we rely
          on the support of dedicated volunteers to help us achieve our mission.
          Our volunteer program requires a minimum commitment of one week to six
          months, and we welcome individuals from all backgrounds to join us in
          making a positive impact.
          <br />
          <b>
            To become a volunteer, please take note of the following
            requirements.
          </b>
          <ul>
            <li>
              For on-field volunteering, it is important to clearly specify the
              amount of time you are able to dedicate and your area of interest.
            </li>
            <li>
              We actively seek volunteers who can assist us with organizing
              events, fundraising, and supporting our campaigns.
            </li>
            <li>
              By working together, we can create meaningful change in our
              society. .
            </li>
          </ul>
        </div>
        <div className="applyForm">
          <span>Apply Now</span>
          <div className="inputGroup">
            <label htmlFor="cname">Your Name:</label>
            <input id="cname" type="text" placeholder="Enter your name" />
          </div>
          <div className="inputGroup">
            <label htmlFor="cnum">Contact Number:</label>
            <input id="cnum" type="number" placeholder="Enter your number" />
          </div>
          <div className="inputGroup">
            <label htmlFor="cid">Email:</label>
            <input id="cid" type="email" placeholder="Enter your email" />
          </div>
          <div className="inputGroup">
            <label htmlFor="fUpload">Select Attachment</label>
            <input id="fUpload" type="file" />
          </div>
          <div className="inputGroup">
            <label htmlFor="pos">What mode of work do you prefer?</label>
            <select id="pos" value={selectedOption} onChange={handleChange}>
              <option value="">Select an option</option>
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          <div className="submitBtn">Submit</div>
        </div>
      </div>
      <div className="openPosBox">
        {openPos.map((pos, index) => (
          <div className="position" key={index}>
            <h2>{pos.positionName}</h2>
            <div className="posSection">
              <span>About the Internship:</span>
              <ol>
                {pos.positionAbout.map((abt, index) => (
                  <li key={index}>{abt}</li>
                ))}
              </ol>
            </div>
            <div className="posSection">
              <span>Roles and responsibilities:</span>
              <ol>
                {pos.positionResp.map((resp, index) => (
                  <li key={index}>{resp}</li>
                ))}
              </ol>
            </div>
            <div className="posSection">
              <span>Perks of volunteering include:</span>
              <ol>
                {pos.positionPerks.map((perks, index) => (
                  <li key={index}>{perks}</li>
                ))}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Volunteer;
