import React from "react";
import "./download.css";
import iniRev from "./Allword/1InitialReview.pdf";

const Ind = () => {
  const openPdf = () => {
    const link = document.createElement("a");
    link.href = iniRev;
    link.download = "download.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="document-table">
      <h2 className="table-heading">INDEX</h2>
      <table>
        <thead>
          <tr>
            <th className="document-title-header">Document Title</th>
            <th className="download-header">Download</th>
          </tr>
        </thead>
        <tbody>
          <tr className="document-row">
            <td className="document-title">Initial Review</td>
            <td className="document-download-cell">
              <button className="document-download-button" onClick={openPdf}>
                Download
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Ind;
