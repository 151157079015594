import React from "react";
import "./boardmember.css";

const BrdTable = () => {
  return (
    <div className="table-container">
      <h1 className="table-title">BOARD MEMBER LIST</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>IEC Members</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Dr. Ravi Kaushik</td>
            <td>
            Chairperson
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Dr. Surabhi Mawari</td>
            <td>Member Secretary</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Dr. Lalit Gupta</td>
            <td>Clinician</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Dr. Nalini Kaushik</td>
            <td>
            Basic Medical Scientist
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>Dr. Proteesh Rana</td>
            <td>Basic Medical Scientist</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Mr. Shubham Garg</td>
            <td>Legal Expert</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Dr. Neera Singh</td>
            <td>Social Scientist</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Ms. Anuradha Sehrawat</td>
            <td>Lay Person</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Dr. Pratap Singh</td>
            <td>Clinician</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Dr. Shalini S.Vaid</td>
            <td>Clinician</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BrdTable;
