import React from "react";
import "./stress.css";
import st1 from "./images1/st1.jpg";
import st3 from "./images1/st3.jpg";
import st4 from "./images1/st4.jpg";

function Stress() {
  return (
    <div className="strmain-container">
      <h3 className="strh">STRESS MANAGEMENT</h3>
      <div className="strcard-container">
        <div className="strcard">
          <img src={st1} alt="example" className="stcard-img" />
        </div>
        <div className="strcard">
          <div className="strcard-text">
            <p className="strdata"><br></br>
            The webinar was organised on October 17, 2021 (Sunday) It was held on the virtual platform "ZOOM". The agenda of the webinar was to "get relief from stress during COVID-19". The COVID-19 pandemic has caused a lot of mental pressure in all our lives, and we feel the stress and anxiety as we all try to navigate a new "NORMAL". Our speaker was Dr. Arti Anand (consultant-clinical psychologist, Sir Ganga Ram Hospital).<br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="strcard-container">
        <div className="strcard">
          <div className="strcard-text">
            <p className="strdata" style={{ textIndent: '40px' ,textAlign:'justify'  }}><br></br>
           She gave us information related to stress, such as what we understand by stress, how stress affects our body, causes of stress, signs, and symptoms of stress, and its management, in which we can use many techniques, such as positive self-talk, visualisation, Jacobson’s progressive muscle relaxation, exercise, mindfulness meditation, and very important reflective listening.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="strcard" style={{ textIndent: '40px' ,textAlign:'justify'  }}>
          <img src={st3} alt="example" className="strcard-img" />
        </div>
      </div>

      <div className="strcard-container">
        <div className="strcard">
          <img src={st4} alt="example" className="strcard-img" />
        </div>
        <div className="strcard">
          <div className="strcard-text">
            <p className="strdata" style={{ textIndent: '40px' ,textAlign:'justify'  }}><br></br>
            
            She also discussed "when should we seek a psychologist" and how helpful it is to our mental health.Have a positive outlook, realistic thinking, resilient attitude, be creative.
              <br></br>
              <br></br>
              
            </p>
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default Stress;
