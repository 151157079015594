import React from "react";
import "./meditation.css";
import m1 from "./images1/m1.jpg";
import m2 from "./images1/m2.jpg";
import m3 from "./images1/m3.jpg";

function Meditaion() {
  return (
    <div className="mmain-container">
      <h3 className="mh">MEDITATION</h3>
      <div className="mcard-container">
        <div className="mcard">
          <img src={m1} alt="example" className="mcard-img" />
        </div>
        <div className="mcard">
          <div className="mcard-text">
            <p
              className="mdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              The workshop was organised on September 11, 2021. It was held on
              the virtual platform "ZOOM". The aim was "EMPOWER YOURSELF WITH
              MEDITATION" for women, with our speaker being B. K. Jayanti Didi
              Ji (Sr. Rajyoga Meditation Teacher). <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="mcard-container">
        <div className="mcard">
          <div className="mcard-text">
            <p
              className="mdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Waves believe meditation is a beautiful and pleasant inner journey
              of the soul, which is a communion with the supreme soul. And if we
              want to reach our higher consciousness, then meditation is the
              only way. <br></br>
              <br></br>The mentor encouraged the participants by informing them
              about her meditation experience and its benefits, not only mental
              but physical, like concentration, anger management, and being
              fearless.
            </p>
          </div>
        </div>
        <div className="mcard">
          <img
            src={m2}
            alt="example"
            className="mcard-img"
            style={{ height: "45rem", width: "40rem" }}
          />
        </div>
      </div>

      <div className="mcard-container">
        <div className="mcard">
          <img src={m3} alt="example" className="mcard-img" />
        </div>
        <div className="mcard">
          <div className="mcard-text">
            <p
              className="mdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Not only this, but if every woman teaches their family the
              qualities of discretion, honesty, and virtue and gives happiness
              to others, then this world will become a heaven. The values given
              by the mentor enriched the audience.<br></br>
              <br></br> At the end of the webinar, she gives us a little
              experience of this heavenly journey we call "meditation."
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meditaion;
