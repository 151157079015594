import React from "react";
import "./gallery.css";
import yc1 from "./images1/yc1.jpg";
import yc2 from "./images1/yc2.jpg";
import g1 from "./images1/g1.jpg";
import g2 from "./images1/g2.jpg";
import g3 from "./images1/g3.jpg";
import yc3 from "./images1/yc3.jpg";
import o1 from "./images1/o1.jpg";
import Ahmain from "./images1/Ahmain.jpg";
import AH2 from "./images1/AH2.jpg";
import AH3 from "./images1/AH3.jpg";
import srv1 from "./images1/srv1.jpg";
import srv2 from "./images1/srv2.jpg";
import EsrM from "./images1/EsrM.jpg";

const Gallery = () => {
  return (
    <div>
      <h1 className="ghead" style={{ color: "#560323" }}>
        GALLERY
      </h1>
      <div className="wrapperg">
        <div className="sliderg">
          <div className="slide-trackg">
            <div className="slideg">
              <img src={o1} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={yc1} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={Ahmain} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={g1} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={AH2} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={g2} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={srv1} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={yc2} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={EsrM} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={g3} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={AH3} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={srv2} alt="gallery" />
            </div>
            <div className="slideg">
              <img src={yc3} alt="gallery" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
