import React from "react";
import "./Footer.css";
import fb from "./assets/facebook.png";
import twitter from "./assets/twitter.png";
import linkedin from "./assets/linkedin.png";
import insta from "./assets/instagram.png";
import ytb from "./assets/youtube.png";
import koo from "./assets/koo.png";
import spotify from "./assets/spotify.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="sb__footer section_padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <h4>About Us</h4>
            <Link to="/fabtus">
              <p>Knowing Waves</p>
            </Link>
            <Link to="/mediacoverage">
              <p>Media Coverage</p>
            </Link>
            <Link to="/press">
              <p>Press Release</p>
            </Link>
            <Link to="/awards">
              <p>Awards and Recognition</p>
            </Link>
            <Link to="/annualrpt">
              <p>Annual Report</p>
            </Link>
          </div>

          <div className="sb__footer-links_div">
            <h4>Impact</h4>
            <Link to="/projects">
              <p>Projects</p>
            </Link>
            <Link to="/workshop">
              <p>Workshops</p>
            </Link>
            <Link to="/webinar">
              <p>Webinars</p>
            </Link>
            <Link to="/events">
              <p>Events</p>
            </Link>
            <Link to="/stories">
              <p>Stories of change</p>
            </Link>
          </div>
          <div className="sb__footer-links_div">
            <h4>Ethics Committee</h4>
            <Link to="/IECabout">
              <p>About Independent Ethics Committee</p>
            </Link>
            <Link to="/dwnpage">
              <p>Download</p>
            </Link>
            <Link to="/boardmember">
              <p>Board Member List</p>
            </Link>
            <Link to="/cert">
              <p>Registration Certificate</p>
            </Link>
          </div>

          <div className="sb__footer-links_div">
            <h4>Contact Us</h4>
            <Link to="/address">
              <p>Address</p>
            </Link>
            <Link to="/legalSupport">
              <p>Legal Support</p>
            </Link>
            <Link to="/counselling">
              <p>Counselling</p>
            </Link>
            <Link to="/medical">
              <p>Medical</p>
            </Link>
            <Link to="/fitness">
              <p>Fitness</p>
            </Link>
          </div>
          <div className="sb__footer-links_div">
            <h4>Get Involved</h4>
            <Link to="/volunteer">
              <p>Volunteer</p>
            </Link>
            <Link to="/intern">
              <p>Intern</p>
            </Link>
            <Link to="/partner">
              <p>Partner</p>
            </Link>
          </div>
          <div className="sb__footer-links_div">
            <h4>Connect with us</h4>
            <div className="socialmedia">
              <a
                href="https://www.facebook.com/Waveswomenempowerment?mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={fb} alt="fb" />
                </p>
              </a>
              <a
                href="https://www.linkedin.com/in/waves-women-empowerment-a36423266/"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={linkedin} alt="linkedin" />
                </p>
              </a>
              <a
                href="https://twitter.com/waveswomenempo1"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={twitter} alt="twitter" />
                </p>
              </a>
              <a
                href="https://www.instagram.com/waveswomenempower/"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={insta} alt="insta" />
                </p>
              </a>
              <a
                href="https://www.youtube.com/channel/UCDvrS1e03AZ_K2kUkZfeZsg/featured"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={ytb} alt="ytb" />
                </p>
              </a>
              <a
                href="https://www.kooapp.com/profile/waves_women_empowerment"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={koo} alt="koo" />
                </p>
              </a>
              <a
                href="https://open.spotify.com/episode/7EMDHu41pkiEhT8S7zkGNl?si=5wGFVCZ7Ru6vFl0Y361nzw"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={spotify} alt="spotify" />
                </p>
              </a>
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="sb__footer-below">
          <div className="<sb__footer-copyright">
            <p>
              @{new Date().getFullYear()} Waves Women Empowerment. All rights
              reserved.
              <br></br>Unique ID:DL/2018/0214033
            </p>
          </div>
          {/* <div className="sb__footer-below-links">
            <Link to="/terms">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </Link>
            <Link to="/privacy">
              <div>
                <p>Privacy</p>
              </div>
            </Link>
            <Link to="/security">
              <div>
                <p>Security</p>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
