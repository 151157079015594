import React from "react";
import "./yoga.css";
import wbyoga1 from "./images1/wbyoga1.jpg";
import wbyoga2 from "./images1/wbyoga2.jpg";
import YWM from "./images1/YWM.jpg";
function Yoga() {
  return (
    <div className="ygmain-container">
      <h3 className="ygh">YOGA</h3>
      <div className="ygcard-container">
        <div className="ygcard">
          <img src={wbyoga1} alt="example" className="ygcard-img" />
        </div>
        <div className="ygcard">
          <div className="ygcard-text">
            <p className="ygdata" style={{ textIndent: '40px' ,textAlign:'justify'  }}>
              The webinar was organised on August 11, 2021 (Wednesday) It was
              held on the virtual platform "ZOOM". The webinar agenda was "YOGA"
              (योग स्वयं की, स्वयं के माध्यम से, स्वयं की यात्रा है|) with
              YOGACHARYA SHALLU as our yoga mentor<br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="ygcard-container">
        <div className="ygcard">
          <div className="ygcard-text">
            <p className="ygdata" style={{ textIndent: '40px' ,textAlign:'justify'  }}>
              The interview aimed at ascertaining the level of awareness among
              the women regarding HIV/AIDS on parameters like causes of HIV,
              ways of prevention, treatment of HIV, attitude towards one having
              HIV, etc.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="ygcard">
          <img src={wbyoga2} alt="example" className="ygcard-img" />
        </div>
      </div>

      <div className="ygcard-container">
        <div className="ygcard">
          <img src={YWM} alt="example" className="ygcard-img" />
        </div>
        <div className="ygcard">
          <div className="ygcard-text">
            <p className="ygdata" style={{ textIndent: '40px' ,textAlign:'justify'  }}>
              She also included different yoga asanas like Balasana,
              Dhanurasana, Salabhasana, etc. As COVID-19 is affecting people
              mentally, yoga is considered to be really helpful in balancing
              mental state and also boosting immunity.
              <br></br>
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yoga;
