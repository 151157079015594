import React from "react";
import "./annualrpt.css";
import imagef from "../images/Images for website.png";

function AnnualReport(props) {
  const openPdf = () => {
    const link = document.createElement("a");
    link.href = props.pdfFile;
    link.download = props.downloadName;
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="anncontainer">
      <img src={props.previewImage} alt="preview" className="annimg" />
      <button className="document-download-button" onClick={openPdf}>
        Download Entire Report
      </button>
    </div>
  );
}

function App() {
  return (
    <div>
      <img src={imagef} alt="annual" style={{ width: "1650px", height: "400px" }} />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <AnnualReport
            pdfFile={require("./Allpdf/Annual Report(2022-2023) (3).pdf")}
            downloadName="Annual_Report_2022_2023.pdf"
            previewImage={require("../images/2022-23.jpeg")}
          />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <AnnualReport
            pdfFile={require("./Allpdf/Annual Report(2021-2022) (2).pdf")}
            downloadName="Annual_Report_2021_2022.pdf"
            previewImage={require("../images/2021-22.jpeg")}
          />
        </div>
      </div>
    </div>
  );
}


export default App;
