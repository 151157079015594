import React from 'react';
import { Link } from 'react-router-dom';
import './webinar.css';

import Omain from "./images1/OMain.jpg";
import StressM from "./images1/StressM.jpg";
import YWM from "./images1/YWM.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="wbcard">
      <img src={imageSrc} alt={title} className="wbcard-image" />
      <h2 className="wbcard-title">{title}</h2>
      <Link to={linkTo} className="wbcard-link">
        <button className="wbcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Webinar = () => {
  return (
    <div className="wbcards-container">
      <h1>WEBINAR</h1>
      <div className="wbrow">
        <Card
          imageSrc={YWM}
          title="YOGA"
          buttonText="Read More"
          linkTo="/yoga"
        />
        <Card
          imageSrc={Omain}
          title="OMICRON"
          buttonText="Read More"
          linkTo="/omicron"
        />
        <Card
          imageSrc={StressM}
          title="STRESS MANAGEMENT"
          buttonText="Read More"
          linkTo="/stress"
        />
      </div>
      {/* <div className="wrow">
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 3"
          buttonText="Read More"
          linkTo="/path/to/page3"
        />
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 4"
          buttonText="Read More"
          linkTo="/path/to/page4"
        />
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 3"
          buttonText="Read More"
          linkTo="/path/to/page3"
        />
      </div> */}
    </div>
  );
};

export default Webinar;
