import React from "react";
import "./yoga1.css";

import YG11 from "./images1/YG11.jpg";
import YG12 from "./images1/YG12.jpg";
import YG13 from "./images1/YG13.jpg";

function Yoga1() {
  return (
    <div className="yg1main-container">
      <h3 className="yg1h">YOGA I</h3>
      <div className="yg1card-container">
        <div className="yg1card">
          <img src={YG11} alt="example" className="yg1card-img" />
        </div>
        <div className="yg1card">
          <div className="yg1card-text">
            <p
              className="yg1data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              This is a two-month project report in which our aim for the
              classes is to reach every woman through digital mediums and teach
              them yoga for free. The classes started on April 1, 2022 (Tuesday)
              and ended on May 30, 2022 (Monday). The goal was to make sure that
              all women and girls of any age have access to quality yoga
              education to make them aware of and understand the importance of
              good health. Waves Women Empowerment has developed into a women's
              development organisation that provides education, health,
              awareness of social issues, and emotional support to women in
              need.
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yg1card-container">
        <div className="yg1card">
          <div className="yg1card-text">
            <p
              className="yg1data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              To achieve its goals, Waves has instituted a variety of different
              projects, such as providing basic education to girls and women.
              <br></br>
              <br></br>
              The schedule was planned from a very basic level to an advanced
              level so that beginners could not hesitate to join the course. In
              the first month, the first 15 days were basic. The yoga mentor
              introduced everyone to light yoga asanas like Surya Namaskar,
              Tadasans, etc. in the next 15-day sessions based on diabetes. In
              which the yoga mentor performs different asanas and gives
              information regarding them. The asanas she taught are Surya
              Namaskar, Trikonasana, Tadasana, Sukhasana, and Padmasana.
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yg1card">
          <img src={YG12} alt="example" className="yg1card-img" />
        </div>
      </div>

      <div className="yg1card-container">
        <div className="yg1card">
          <img src={YG13} alt="example" className="yg1card-img" />
        </div>
        <div className="yg1card">
          <div className="yg1card-text">
            <p
              className="yg1data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              <br></br>
              The second month was focused on breathing exercises, and the
              agenda was asthma along with female menstrual disorders.<br></br>
              <br></br>
              Generally, people don't know the benefits of yoga and its
              significance, but we were happy to learn that our initiative made
              people realise its importance and was able to change their
              lifestyles.
              <br></br>
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Yoga1;
