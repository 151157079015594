import React from "react";
import Arti_mam from "./images1/Arti_mam.jpg";
import Contactus from "./contactfrm";
import "./counselling.css";

const Card = ({ imageSrc, title, buttonText, linkTo, desc }) => {
  return (
    <div className="pjcard-cont mx-auto">
      <img src={imageSrc} alt={title} className="pjcard-image-cont" />
      <h2 className="pjcard-title-cont">{title}</h2>
      <h6 className="desc-cont ">{desc}</h6>
    </div>
  );
};

const Counselling = () => {
  return (
    <>
      <div class="card-cont w-100">
        <h1 class="card-title-cont">COUNSELLING</h1>
        <div class="card-body-cont text-center">
          There are times when we need guidance from a third person to move
          ahead in life. Waves aims to provide group counselling for social
          issues and problems, marriage counselling, student counselling, parent
          counselling, career counselling, and even support groups for women.
          If, you want any help to concern any of the above mentioned we will
          provide you the best services.
        </div>

        <div className="pjcards-container-cont">
          <h1>Support Member</h1>
          <div className="pjrow-cont">
            <Card
              imageSrc={Arti_mam}
              title=" Dr. Arti Anand "
              desc="(Consultant-Clinical Psychologist, Sir Ganga Ram Hospital)"
            />
          </div>
        </div>
      </div>
      <Contactus />
    </>
  );
};

export default Counselling;
