import React from "react";
import img1 from "./images1/img1.jpg";
import img2 from "./images1/img2.jpg";
import img3 from "./images1/img3.jpg";
import "./fabtus.css";
import devvvi from "./images1/devvvi.jpg";

import work from "./work/t(1).webp";
import work1 from "./work/t.webp";
import work2 from "./work/t(6).webp";
import work3 from "./work/t(2).webp";
import work4 from "./work/v.png";
import work5 from "./work/t(4).webp";


const AboutUs = () => {
  return (
    <div>
      
      <div className="cardabt-container">
        <div className="cardabt">
          <div>
            <h1>ABOUT US</h1>
          </div>

          <img className="cardabt-image" src={devvvi} alt="Card" />
          <p className="cardabt-text">
            <br />
            Waves Women Empowerment Trust, was started in 2018 in New Delhi by
            Mrs. Kanta Sharma. Its primary focus is on strengthening women in
            different manners possible emotionally, physically, mentally, and
            educationally in various states of India like Delhi, Haryana, Uttar
            Pradesh, etc. The NGO is working in the field of women's empowerment
            and self-awareness.
            <br />
            Every woman acts as a pillar of the family and is only not required
            to be strong but also to be competent in shouldering the weight and
            responsibility of the entire family. We at WAVES make special
            emphasis on stimulating the status of women through literacy,
            education, training, and especially by providing awareness. We
            encourage whole and equal access to women for education, literacy,
            and training, and we try to remove all hindrances that occur on
            their way to success.
            <br />
            We provide platforms to challenge oppressive norms and conduct
            programs to increase self-confidence, and awareness about sexuality
            and build leadership in women.
            <br />
            Our detailed courses/programs for women empowerment may be seen in
            the next slide of our work
          </p>
        </div>
      </div>
      <br />


      
      <div className="containera">
        <div>
          <h1>Our Work</h1>
        </div>
        <div className="our-work-container">
        <div className="container-fluid">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">HEALTH AWARENESS AND PROGRAM</h3>
              <br />
              <ul className="card-text">
                <li>Awareness about HIV/AIDS/Sexually transmitted diseases.</li>
                <li>
                  Awareness of thyroid/diabetes/female cancer/iron deficiency in
                  ladies and obesity.
                </li>
                <li>Awareness of Water sanitation & self-hygiene.</li>
                <li>
                  Awareness about how to take care during pregnancy/after
                  delivery, improvement in the identification and care of
                  low-birth-weight newborn babies, and children of 0 to 5 years
                  of age have better nourishment.
                </li>
                <li>Teach about sexual abuse and harassment</li>
                <li>Awareness and nutritious healthy eating habits.</li>
                <li>Sex education.</li>
                <li>Family planning.</li>
                <img src={work3} alt="img" className="work-img"/>
              </ul>             
            </div>
          </div>
        </div>

        <div className="container-fluid ">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">EDUCATION</h3>
              <br />
              <ul className="card-text">
                <li>Vedic Shastrkulam</li>
                <li>Teaching women to read and write.</li>
                <li>BETI BACHO-BETI PADHAO.</li>
                <li>
                  Teach and support clean Indian mission/electricity/water/tree.
                </li>
                <li>Personal finance maintenance.</li>
                <li>Awareness about surrogacy and legal rights.</li>
                <li>Basic Computer Course and IT skills.</li>
                <li>Basic English speaking course</li>
                <li>Digital Education</li>
                <img src={work1} alt="img" className="work-img"/>
              </ul>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">SELF EMPLOYMENT</h3>
              <br />
              <ul className="card-text">
                <li>
                  Self-employment workshop and create new ideas for group
                  employment.
                </li>
                <li>Sticking.</li>
                <li>Beautician.</li>
                <li>Soft toy-making classes</li>
                <li>Cooking classes.</li>
                <li>Yoga classes.</li>
                <li>Astrology classes</li>
                <li>
                  Handmade things like (PAPAD, ACHAAR, BADI, CHTAI, PURSE,
                  ORGANIC FARMING ETC). We help in delivering material sell and
                  give profit and good for our trust.
                </li>
                <li>Self-help groups</li>
                <img src={work5} alt="img" className="work-img"/>
              </ul>            
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">YOGA</h3>
              <br />
              <ul className="card-text">
                <li>Meditation.</li>
                <li>Self-realization.</li>
                <li>Improve inner strength.</li>
                <li>Anger management.</li>
                <li>Depression management.</li>
                <li>Confidence management classes.</li>
                <li>Self-respect and love yourself.</li>
                <li>Self-healing.</li>  
                <img src={work} alt="img" className="work-img"/>           
              </ul>            
            </div>         
          </div>
        </div>

        <div className="container-fluid">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">COUNSELLING</h3>
              <br />
              <ul className="card-text">
                <li>Group counselling and social issues/problems.</li>
                <li>Marriage counselling.</li>
                <li>Domestic violence.</li>
                <li>Student counselling.</li>
                <li>Parent counselling.</li>
                <li>Career counselling.</li>
                <li>Support groups of women.</li>
                <img src={work2} alt="img" className="work-img"/>
              </ul>          
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card w-75 mb-4">
            <div className="card-body">
              <h3 className="card-title">LEGAL RIGHTS OF WOMEN</h3>
              <br />
              <ul className="card-text">
                <li>Protection of women from domestic violence.</li>
                <li>Dowry prohibition.</li>
                <li>Maternity benefits.</li>
                <li>Right to privacy.</li>
                <li>Political rights.</li>
                <img src={work4} alt="img" className="work-img"/>
              </ul>            
            </div>
          </div>
        </div>
        </div>
        </div>
      
      
      <div className="container">
        <div className="card-header">
          <h1>OUR AIMS</h1>
        </div>
        <br />
        <div className="card-body">
          <ul className="card-text-aims">
            <li>
              Constituent all practices that prejudice against women; abetment
              women to initiate and realize their rights, including those that
              relate to reproductive and sexual health.
            </li>
            <li>
              Abolish discriminatory practices against employers, against women,
              such as those based on evidence of contraceptive use or maternity
              status.
            </li>
            <li>
              Facilitate through laws and order and other relevant measures for
              women to integrate the roles of child-bearing, breast-feeding, and
              child-rearing by involving in the workforce.
            </li>
            <li>
              Promoting the fulfillment of women’s potential through education,
              skill development, and employment, giving paramount significance
              to the refusal of poverty, illiteracy, and ill health among women.
            </li>

            <li> Eliminating violence against women.</li>

            <li>
              Taking appropriate measures to improve women’s ability to earn
              income beyond traditional occupations, achieve economic
              self-reliance, and ensure women’s equal access to the labor market
              and social security system.
            </li>

            <li>
              Therefore, we should aim to create an environment of integrity for
              all our mothers, sisters, and daughters. We must inculcate the
              confidence of women to make their own decisions and enable them at
              every stage of life, thus we, Waves Women Empowerment Trust, are
              striving towards bringing women empowerment.
            </li>
          </ul>
        </div>
      </div>

      <section>

      <div className="our-team-container">
        <div className="row">
          <h1>OUR TEAM</h1>
        </div>
        <div className="row">
          {/* Column 1 */}
          {/* <div className="column"> */}
            <div className="card">
            <div className="img-container team-img-container"> 
             <img src={img1} alt="img" />
              </div>
              <h3>Kanta Sharma </h3>
              <p>President</p>
              <div className="icons">
                <i className="fab fa-twitter" />
                <i className="fab fa-linkedin" />
                <i className="fas fa-envelope" />
              </div>
            {/* </div> */}
          </div>
          {/* Column 2 */}
          {/* <div className="column"> */}
            <div className="card">
              <div className="img-container team-img-container">
                <img src={img2} alt="img" />
              </div>
              <h3>Babita Tiwari</h3>
              <p>Secretary</p>
              <div className="icons">
                <i className="fab fa-twitter" />
                <i className="fab fa-linkedin" />
                <i className="fab fa-github" />
                <i className="fas fa-envelope" />
              </div>
            </div>
          {/* </div> */}
          {/* Column 3 */}
          {/* <div className="column"> */}
            <div className="card">
              <div className="img-container team-img-container">
                <img src={img3} alt="img" />
              </div>
              <h3>Surabhi Rohilla </h3>
              <p>Treasurer</p>
              <div className="icons">
                <i className="fab fa-twitter" />
                <i className="fab fa-linkedin" />
                <i className="fas fa-envelope" />
              </div>
            </div>
          {/* </div> */}
        </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
