import React from "react";
import "./download.css";
import CheckList from "./checklist";
import DocumentList from "./download";
import Ind from "./d1";
import Guidelines from "./gdlin";

const DwnloadPage = () => {
  return (
    <div>
      <div>
        <Ind />
      </div>
      <div>
        <CheckList />
      </div>
      <div>
        <DocumentList />
      </div>
      <div>
        <Guidelines />
      </div>
    </div>
  );
};

export default DwnloadPage;
