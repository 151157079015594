import React, { useEffect, useState } from "react";
import "./p.css";
import goodera from "./images1/goodera.jpg";
import eto from "./images1/eto.jpg";
import cogni2 from "./images1/cogni2.jpg";
import farmer from "./images1/farmer.jpg";
import partnerWithUs from "./data/partWithUs";

const PartWithUsCards = ({ heading, subtext, image, i }) => (
  <div className="part__box">
    <div className="part__imgBox">
      <img src={image} alt="part" />
    </div>
    <div className="part__textBox">
      <div className="part__heading" id={`part__heading_${i}`}>
        {heading}
      </div>
      <div className="part__text">{subtext}</div>
    </div>
  </div>
);

const PartnerCards = ({ img }) => (
  <div className="partner__card">
    <img src={img} alt="partners" />
  </div>
);

const Partners = () => {
  const [partWithUs, setPartWithUs] = useState([]);
  useEffect(() => {
    setPartWithUs(partnerWithUs);
  }, []);
  return (
    <div className="p__box">
      <span className="p__sec_heading">Partner with Us</span>
      <div className="p__quote">
        <i>
          "The best way to find yourself is to lose yourself in the service of
          others."
        </i>
        <i>- Mahatma Gandhi</i>
      </div>
      <div className="p__subheading">
        We urge you to be a part of our cause and help us to bring the change.
        <br /> Partner with us for a better community.
      </div>
      <span className="p__sec_subheading">Ways to Partner with Us</span>
      <div className="p__cards">
        {partWithUs.map((part, i) => (
          <PartWithUsCards {...part} i={i} key={i} />
        ))}
      </div>
      <span className="p__sec_subheading">Our Partners</span>
      <div className="p__cards" style={{ gap: "2.5rem" }}>
        {[goodera, eto, cogni2, farmer].map((img, i) => (
          <PartnerCards key={i} img={img} />
        ))}
      </div>
    </div>
  );
};

export default Partners;
