import React from "react";
import "./ygcmpstories.css";

function Ygcmpstories() {
  return (
    <div className="ygcstmain-container">
      <h3 className="ygcsth">STORIES OF CHANGE THROUGH YOGA CAMP</h3>
      <div className="ygcstcard-container">
        <div className="ygcstcard">
          <div className="ygcstcard-text">
            <p
              className="ygcstdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ 45-year-old Megha is a homemaker who joined our yoga camp
              through a family friend. In a conversation, she claims that in 20
              years, for the very first time, she came out of her house and
              thought about her. She said that her health was deteriorating, but
              through yoga, it got better, and even meditation calmed her
              anxiety and gave her a way to more positivity. It is very happy to
              see her taking care of her health since the woman is the caretaker
              of the family but lacks when it comes to herself. We are happy to
              connect with the homemakers and see the change.
              <br></br>
            </p>
          </div>
        </div>
      </div>
      <div className="ygcstcard-container">
        <div className="ygcstcard">
          <div className="ygcstcard-text">
            <p
              className="ygcstdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ Vidhushi, 30, is employed by a business company. Her health
              deteriorated as a result of her lengthy work hours, and at this
              age, she was dealing with several health problems. Her exposure to
              yoga assisted her in altering her lifestyle and improving her
              health. She now dedicates at least 30 minutes a day to herself.
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ygcmpstories;
