import React from "react";
import { Link } from "react-router-dom";
import "./mediacoverage.css";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="mvccard">
      <h2 className="mvccard-title">{title}</h2>
      <Link to={linkTo} className="mvccard-link">
        <button className="mvccard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Mediacov = () => {
  return (
    <div className="mvccards-container">
      <h1>MEDIA COVERAGE</h1>
      <div className="mvcrow">
        <Card
          title=" Indian women organize midnight walk to reclaim the unsafe streets "
          buttonText="Read More"
          linkTo="http://dhunt.in/J9ZHV?s=a&uu=0x8dc56614c77dcd52&ss=pd"
        />
      </div>
    </div>
  );
};

export default Mediacov;
