import React from "react";
import { Link } from "react-router-dom";
import "./workshop.css";
import Ahmain from "./images1/Ahmain.jpg";
import MD from "./images1/MD.jpg";
import YC from "./images1/YC.jpg";
import FacialYogaImage from "./FacialYogaImage/Front image (2).jpg";
import swasthamatrutva from "./swasthamatrutva/img4 (5).jpg";
import graphial from "./graphial/Front image.jpg";
import financial from "./financial/Front image.jpg";




const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="wcard">
      <img src={imageSrc} alt={title} className="wcard-image" />
      <h2 className="wcard-title">{title}</h2>
      <Link to={linkTo} className="wcard-link">
        <button className="wcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Workshop = () => {
  return (
    <div className="wcards-container">
      <h1>WORKSHOPS</h1>
      <div className="wrow">
        <Card
          imageSrc={Ahmain}
          title="AIDS/HIV"
          buttonText="Read More"
          linkTo="/aids"
        />
        <Card
          imageSrc={MD}
          title="MEDITATION"
          buttonText="Read More"
          linkTo="/meditation"
        />
        <Card
          imageSrc={YC}
          title="YOGA CAMP"
          buttonText="Read More"
          linkTo="/ycmp"
        />
    
      </div>
    
        <div className="wrow">
      <Card
          imageSrc={FacialYogaImage}
          title="FACIAL YOGA"
          buttonText="Read More"
          linkTo="/facialYoga"
        />
        <Card
          imageSrc={graphial}
          title="GRAPHAOLOGY"
          buttonText="Read More"
          linkTo="/graphial"
        />
        <Card
          imageSrc={swasthamatrutva}
          title="SWASTHA MATRUTVA"
          buttonText="Read More"
          linkTo="/swasthamatrutva"
        />
      </div>

      <div className="wrow">
      <Card
          imageSrc={financial}
          title="FINANCIAL LITERACY"
          buttonText="Read More"
          linkTo="/financial"
        />
      </div>
    </div>


  );
};

export default Workshop;
