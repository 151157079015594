import React from "react";
import "./IECabout.css";
import IECMain from "./images1/IECMain.jpg";

function IECAbout() {
  return (
    <div className="containerIecabout">
      <img src={IECMain} alt="placeholder" className="full-width-img" />
      <div
        className="cardiec"
        style={{ textIndent: "5rem", justifyContent: "center" }}
      >
        <p style={{ textAlign: "justify" }}>
          Waves-IEC, is an independent body constituted of medical and
          non-medical professionals, whose responsibility is to ensure the
          protection of the rights of research study participants. Our
          Independent Ethics Committee(IEC) is duly registered with Central
          Drugs Standard Control Organization (for Clinical Trials- Regulatory
          Studies) and Department of Health Research (for Non- Regulatory
          Studies). Waves Women Empowerment Trust welcomes all consultants and
          resident doctors, DNB/MD/MS students to conduct clinical trials and
          academic studies research as we are legally authorized to grant
          approval.<br></br>
          <br></br>
        </p>
      </div>
      <div
        className="cardiec"
        style={{ textIndent: "5rem", justifyContent: "center" }}
      >
        <p style={{ textAlign: "justify" }}>
          <p className="dt" style={{ textAlign: "center" }}>
            <br></br>
            Email: iecwwe@gmail.com <br></br>Address:9/350A, Gali.no: 9, Lalita
            Park, Laxmi Nagar,Delhi-110092<br></br>Contact: +91-9911179893
          </p>
        </p>
      </div>
    </div>
  );
}

export default IECAbout;
