import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Blog = () => {
  const location = useLocation();
  const divRef = useRef(null);
  const blog = location.state;
  console.log(blog);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, []);
  return (
    <>
      <div ref={divRef} />
      <div className="primaryBox">
        <div
          style={{
            fontSize: "3rem",
            fontWeight: 700,
            textTransform: "uppercase",
            textAlign: "center",
            color: "#560826",
          }}
        >
          {blog.blogName}
        </div>
        <img
          src={blog.blogImage}
          alt="img"
          style={{ width: "80%", minHeight: "25rem" }}
        />
        <p
          style={{
            lineHeight: "2rem",
            letterSpacing: "1px",
            fontSize: "1.2rem",
            fontWeight: 500,
            textAlign: "justify",
          }}
        >
          {blog.blogText}
        </p>
      </div>
    </>
  );
};

export default Blog;
