import React from "react";
import "./donate.css";
import sbiLogo from "./assets/SBILogo.png";

const DonatePage = () => {
  return (
    <div className="donate-page">
      <h1>Donate</h1>
      <div className="donate-info">
        <div className="donate-methods">
          <h2>How You Can Donate:</h2>
          <ul>
            <li>
              <strong>BY CHEQUE:</strong> You may kindly donate by sending a
              Cheque or Bank Draft drawn in favor of WAVES WOMEN EMPOWERMENT
              payable at Karnataka Bank.
            </li>
            <li>
              <strong>DIRECT DEPOSIT:</strong> You can also deposit directly
              into our Bank Account.
            </li>
            <li>
              <strong>ONLINE TRANSFER:</strong> You may kindly donate by sending
              a Cheque or Bank Draft drawn in favor of WAVES WOMEN EMPOWERMENT
              payable at Karnataka Bank.
            </li>
            <li>
              <strong>DEPOSIT IN BRANCHES:</strong> You can also deposit
              directly into our Branches as well.
            </li>
            <li>
              <strong>BY MOBILE WALLET:</strong> You can use Mobile Wallet for
              donation Money Transfer.
            </li>
          </ul>
        </div>
        <div className="bank-details">
          <div className="logo-container">
            <img src={sbiLogo} alt="SBI Logo" className="logo" />
          </div>
          <div className="bank-details-content">
            <p>Account No: 1942000100287001</p>
            <p>Account Name: WAVES WOMEN EMPOWERMENT</p>
            <p>Bank: Karnataka Bank</p>
            <p>IFSC code: KARB0000194</p>
          </div>
        </div>
      </div>
      <div className="noted">
        <p>
          Kindly provide us with Pay In Slip scanned copy on our email id:
          waveswomenempower@gmail.com along with your complete address, to
          enable us to send you a donation receipt along with income tax
          exemption certiﬁcate copy.
        </p>
      </div>
      {/* <div className="note">
        <p>
          All the donations to WAVES WOMEN EMPOWERMENT are tax exempted under
          80G of the Indian Income Tax Act.
        </p>
      </div> */}
    </div>
  );
};

export default DonatePage;
