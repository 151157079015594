import React from "react";
import { Link } from "react-router-dom";
import "./events.css";

import draw1 from "./images1/draw1.jpg";
import EsrM from "./images1/EsrM.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="ecard">
      <img src={imageSrc} alt={title} className="ecard-image" />
      <h2 className="ecard-title">{title}</h2>
      <Link to={linkTo} className="ecard-link">
        <button className="ecard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Events = () => {
  return (
    <div className="ecards-container">
      <h1>EVENTS</h1>
      <div className="erow">
        <Card
          imageSrc={draw1}
          title="DRAWING COMPETITION"
          buttonText="Read More"
          linkTo="/drawing"
        />
        <Card
          imageSrc={EsrM}
          title="EMPOWERMENT SURVEY"
          buttonText="Read More"
          linkTo="/survey"
        />
      </div>
    </div>
  );
};

export default Events;
