import React from "react";
import Slider from "./Slider";
import ReviewSlider2 from "./review2";
import SloganSlider from "./slogan";
import OurWork from "./ourwork";
import Merchandise from "./merchandise";
import Gallery from "./gallery";
import OurBlogs from "./OurBlogs";

const HomePage = () => {
  return (
    <div>
      <Slider />
      <SloganSlider />
      <OurWork />
      <ReviewSlider2 />
      <Merchandise />
      <OurBlogs />
      <Gallery />
    </div>
  );
};

export default HomePage;
