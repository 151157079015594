import React from "react";
import "./ycmp.css";
import yc1 from "./financial/3.png";
import yc2 from "./financial/2.png";
import yc3 from "./financial/1.png";

export default function Financial() {
  return (
    <div className="ycmain-container">
      <h3 className="yh">Financial Literacy</h3>
      <div className="yccard-container">
        <div className="mcard">
          <img src={yc1} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Waves Women Empowerment conducted a Financial Literacy workshop with a view to Empower the Future of women through Financial learnings. <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
            Relive the insightful virtual workshop on "Unlocking Financial Freedom" that took place on July 28, 2023, via Zoom. This transformative event illuminated the path to financial prosperity by delving into essential topics such as the significance of saving, investing, budgeting, and managing bank accounts. Led by the esteemed company,  Goodera. the volunteers who took the initiative were from DCB bank who came forward to educate our audience. The participants gained valuable insights into making informed financial decisions. With interactive engagement and expert guidance, attendees were equipped with the tools to navigate their financial journey with confidence. <br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc2} alt="example" className="yccard-img" />
        </div>
      </div>
  


      <div className="yccard-container">
      <div className="yccard">
          <img src={yc3} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             NOTE: You can check out our social media page for more insightful sessions and learn for free <br></br>
            </p>
          </div>
        </div>
      </div>


    </div>
    
  );
}


