import React from "react";
import { Carousel } from "react-bootstrap";
import collage1 from "./images1/collage1.jpg";
import collage2 from "./images1/collage2.jpg";
import collage3 from "./images1/collage3.jpg";

const Slider = () => {
  return (
    <Carousel interval={3000} pause={false} fade>
      <Carousel.Item>
        <img className="d-block w-100" src={collage1} alt="First slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={collage2} alt="Second slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={collage3} alt="Third slide" />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
