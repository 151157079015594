import React from "react";
import "./ycmp.css";
import yc1 from "./FacialYogaImage/11.png";
import yc2 from "./FacialYogaImage/12.png";
import yc3 from "./FacialYogaImage/Screenshot (1096).png";
import yc4 from "./FacialYogaImage/15.png";
import yc5 from "./FacialYogaImage/Screenshot (1099).png";

export default function FacialYoga() {
  return (
    <div className="ycmain-container">
      <h3 className="yh">Facial Yoga</h3>
      <div className="yccard-container">
        <div className="mcard">
          <img src={yc1} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
             We held a virtual workshop on “Get Aware with The Real Face Yoga”on July 2, 2023, using the well-liked online platform Google Meet. The goal of this interesting and educational webinar was to increase participants' awareness of both the amazing advantages of face yoga and the value of vaccinations in preserving general health. <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              The workshop explored the fascinating world of face yoga, a natural and holistic method of facial rejuvenation, under the direction of our acclaimed speaker Ms. Neelam Lithoriya . The opportunity to learn about various facial workouts, methods, and routines that tighten and tone the muscles in the face and neck. The speaker provided insightful information on how including face yoga in daily activities can promote youthful and glowing skin, lessen ageing symptoms, and improve facial well-being..<br></br>
              <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc2} alt="example" className="yccard-img" />
        </div>
      </div>

      <div className="yccard-container">
        <div className="mcard">
          {/* <img src={yc3} alt="example" className="yccard-img" /> */}
          <img
            src={yc3}
            alt="example"
            className="yccard-img"
            style={{ height: "45rem", width: "40rem" }}
          />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              In addition to exploring the realm of face yoga, the webinar also shed light on the significance of vaccinations in safeguarding public health. Our speaker provided valuable information on the safety and importance of vaccinations, dispelling myths and misconceptions surrounding them. Participants gained a deeper understanding of the role vaccines play in preventing the spread of diseases, protecting individuals, and contributing to the collective well-being of communities..{" "}
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="yccard-container">
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
          The workshop was a unique opportunity for individuals interested in natural facial rejuvenation techniques and health-conscious practices to gather insights from an expert in the field. Attendees had the chance to interact with the speaker through a live question-and-answer session, where they could clarify doubts and seek personalized advice.
          <br></br>
            </p>
          </div>
        </div>
        <div className="yccard">
          <img src={yc4} alt="example" className="yccard-img" />
        </div>
      </div>


      <div className="yccard-container">
      <div className="yccard">
          <img src={yc5} alt="example" className="yccard-img" />
        </div>
        <div className="yccard">
          <div className="yccard-text">
            <p
              className="ycdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              We would like to extend our sincere thanks to Ms. Neelam Lithoriya for sharing their expertise and providing valuable guidance during the webinar. We also express our gratitude to all the participants who joined us in this enriching session. <br></br>
            </p>
          </div>
        </div>
      </div>


    </div>
    
  );
}


