import React from "react";
import "./slogan.css";

function SloganSlider() {
  return (
    <div className="slider-container">
      <div className="slider-text">
        "Together we can strengthen the status of women in our society"
      </div>
    </div>
  );
}

export default SloganSlider;
