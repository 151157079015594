import React from "react";
import logo2 from "../images/logo2.png";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark ">
        <div className="container-fluid">
          <Link to="/">
            <img className="logo" src={logo2} alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto ">
              <li className="nav-link">
                <Link to="/">Home</Link>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </span>
                <ul className="dropdown-menu">
                  <Link to="/fabtus">
                    <li className="dropdown-item">Knowing Waves</li>
                  </Link>
                  <Link to="/mediacoverage">
                    <li className="dropdown-item">Media Coverage</li>
                  </Link>
                  <Link to="/awards">
                    <li className="dropdown-item">Awards and Recognition</li>
                  </Link>
                  <Link to="/press">
                    <li className="dropdown-item">Press Release</li>
                  </Link>
                  <Link to="/annualrpt">
                    <li className="dropdown-item">Annual Report</li>
                  </Link>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Impact
                </span>
                <ul className="dropdown-menu">
                  <Link to="/projects">
                    <li className="dropdown-item">Projects</li>
                  </Link>
                  <Link to="/workshop">
                    <li className="dropdown-item">Workshop</li>
                  </Link>
                  <Link to="/webinar">
                    <li className="dropdown-item">Webinars</li>
                  </Link>
                  <Link to="/events">
                    <li className="dropdown-item">Events</li>
                  </Link>
                  <Link to="/stories">
                    <li className="dropdown-item">Stories Of Change</li>
                  </Link>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Ethics Committee
                </span>
                <ul className="dropdown-menu">
                  <Link to="/IECabout">
                    <li className="dropdown-item">
                      About Independent Ethics Committee
                    </li>
                  </Link>
                  <Link to="/dwnpage">
                    <li className="dropdown-item">Download</li>
                  </Link>
                  <Link to="/boardmember">
                    <li className="dropdown-item">Board Member List</li>
                  </Link>
                  <Link to="/cert">
                    <li className="dropdown-item">Registration Certificate</li>
                  </Link>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Contact Us
                </span>
                <ul className="dropdown-menu">
                  <Link to="/address">
                    <li className="dropdown-item">Address</li>
                  </Link>
                  <Link to="/legalSupport">
                    <li className="dropdown-item">Legal Support</li>
                  </Link>
                  <Link to="/counselling">
                    <li className="dropdown-item">Counselling </li>
                  </Link>
                  <Link to="/medical">
                    <li className="dropdown-item">Medical </li>
                  </Link>
                  <Link to="/fitness">
                    <li className="dropdown-item">Fitness </li>
                  </Link>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Get Involved
                </span>
                <ul className="dropdown-menu">
                  <Link to="/volunteer">
                    <li className="dropdown-item">Volunteer </li>
                  </Link>
                  <Link to="/intern">
                    <li className="dropdown-item">Intern</li>
                  </Link>
                  <Link to="/partner">
                    <li className="dropdown-item">Partners </li>
                  </Link>
                </ul>
              </li>
              <Link to="/donate">
                <li className="nav-itembtn">
                  <span className="nav-btnlink">Donate</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
