import img1 from "../images1/patrnerWithUs/CSR.png";
import img2 from "../images1/patrnerWithUs/Event and Marketing.png";
import img3 from "../images1/patrnerWithUs/Employee Engagement.png";

const partnerWithUs = [
  {
    heading: "CORPORATE SOCIAL RESPONSIBILITY",
    subtext:
      "Accomplish your CSR objectives with us by supporting high-impact initiatives in nutrition, healthcare, education, and women's empowerment.",
    image: img1,
  },
  {
    heading: "CAUSE MARKETING & EVENTS",
    subtext:
      "Encourage brand collaborations that use the strength of your background and our storytelling to develop campaigns that distinguish your brand.",
    image: img2,
  },
  {
    heading: "Employee Engagement",
    subtext:
      "Establish a work environment where employees feel appreciated for doing more than just their duties and important volunteer work",
    image: img3,
  },
];

export default partnerWithUs;
