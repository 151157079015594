import React from "react";
import { Link } from "react-router-dom";
import "./press.css";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="prscard">
      <h2 className="prscard-title">{title}</h2>
      <Link to={linkTo} className="prscard-link">
        <button className="prscard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Press = () => {
  return (
    <div className="prscards-container">
      <h1>PRESS RELEASE</h1>
      <div className="prsrow">
        <Card
          title=" वेव्स वूमेन एंपावरमेंट ट्रस्ट ने महिलाओं के लिए चलाया- जागरूकता अभियान "
          buttonText="Read More"
          linkTo="https://shuru.page.link/sXrjDA8YY9Ryjrkr7"
        />
        <Card
          title=" वेव्स वूमेन एंपावरमेंट ने आयोजित किया चित्रकला की प्रतियोगिता "
          buttonText="Read More"
          linkTo="https://shuru.page.link/xaJwfTHXNhkZojBK9"
        />
        <Card
          title=" भारत के महारथी के रूप में वेव्स वूमेन एंपावरमेंट ट्रस्ट की सचिव बबीता तिवारी को किया सम्मानित"
          buttonText="Read More"
          linkTo="https://shuru.page.link/VeMGAkrqKRAnwJSGA"
        />
      </div>
      <div className="prsrow">
        <Card
          title=" वेव्स वुमन एंपावरमेंट’ ट्रस्ट द्वारा  का आयोजित मेडिटेशन वेबिनार "
          buttonText="Read More"
          linkTo="https://shuru.page.link/xd6uhcTJxWarwufW6"
        />
        <Card
          title=" वेव्स वुमन एमपावरमेंट ट्रस्ट कर रहा है आयोजित- लेख प्रतियोगिता "
          buttonText="Read More"
          linkTo="https://shuru.page.link/aHs4f25amDpdvU3V8"
        />
        <Card
          title=" वेव्स वुमन एमपावरमेंट ट्रस्ट द्वारा महिलाओं के लिए चलाया जा चुका है तनाव मुक्ति अभियान "
          buttonText="Read More"
          linkTo="https://shuru.page.link/YA5QR8BZzhwP1vas9"
        />
      </div>
    </div>
  );
};

export default Press;
