import React, { useEffect, useState } from "react";
import "./Intern.css";
import data from "./data/internships.json";

const Intern = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [openPos, setOpenPos] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setOpenPos(data);
  }, []);
  return (
    <div className="box">
      <span className="pageHeading">Intern with Waves</span>
      <div className="applyBox">
        <p className="desc">
          Our organization provides exciting internship and volunteer
          opportunities for those who are passionate about making a positive
          impact in the society. We strongly believe that every individual has
          the potential to create real change and together, we can achieve great
          things. At our organization, we welcome individuals who are eager to
          join us in our mission to make a difference. We encourage you to
          explore the various positions currently available and apply for the
          one that best fits your interests and skills. Let's work together to
          create a better world for everyone.
          <br />
          <b>You may apply for any of the positions open below.</b>
        </p>
        <div className="applyForm">
          <span>Apply Now</span>
          <div className="inputGroup">
            <label htmlFor="cname">Your Name:</label>
            <input id="cname" type="text" placeholder="Enter your name" />
          </div>
          <div className="inputGroup">
            <label htmlFor="cnum">Contact Number:</label>
            <input id="cnum" type="number" placeholder="Enter your number" />
          </div>
          <div className="inputGroup">
            <label htmlFor="cid">Email:</label>
            <input id="cid" type="email" placeholder="Enter your email" />
          </div>
          <div className="inputGroup">
            <label htmlFor="fUpload">Select Attachment</label>
            <input id="fUpload" type="file" />
          </div>
          <div className="inputGroup">
            <label htmlFor="pos">Select the position you are applying for</label>
            <select id="pos" value={selectedOption} onChange={handleChange}>
              <option value="">Select an option</option>
              <option value="Fundraiser">Fundraiser</option>
              <option value="Surveyor">Surveyor</option>
              <option value="Social Media Manager">Social Media Manager</option>
              <option value="Graphic Designer">Graphic Designer</option>
              <option value="Video Editor">Video Editor</option>
              <option value="Content Writer">Content Writer</option>
            </select>
          </div>

          <div className="submitBtn">Submit</div>
        </div>
      </div>
      <div className="openPosBox">
        {openPos.map((pos, index) => (
          <div className="position" key={index}>
            <h2>{pos.positionName}</h2>
            <div className="posSection">
              <span>About the Internship:</span>
              <p>{pos.positionAbout}</p>
            </div>
            <div className="posSection">
              <span>
                Selected intern's day-to-day responsibilities include:
              </span>
              <ol>
                {pos.positionResp.map((resp, index) => (
                  <li key={index}>{resp}</li>
                ))}
              </ol>
            </div>
            <div className="posSection">
              <span>Position Location and Requirements:</span>
              <ol>
                {pos.positionLocAndCom.map((locAndCom, index) => (
                  <li key={index}>{locAndCom}</li>
                ))}
              </ol>
            </div>
            <div className="posSection">
              <span>Candidate requirements:</span>
              <ol>
                {pos.positionReq.map((req, index) => (
                  <li key={index}>{req}</li>
                ))}
              </ol>
            </div>
            <div className="posSection">
              <span>Perks:</span>
              <ol>
                {pos.positionPerks.map((perks, index) => (
                  <li key={index}>{perks}</li>
                ))}
              </ol>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Intern;
