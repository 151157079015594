import React from "react";
import "./download.css";

import ExpRev from "./Allword/2ExpeditedReview.pdf";
import ExempRev from "./Allword/3ExemptionFromReview.pdf";
import ContRevARF from "./Allword/3ContinuingReviewAnnualreportformat.pdf";
import Amend from "./Allword/5ApplicationFormForAmendments.pdf";
import ProtViol from "./Allword/6ProtocolViolation.pdf";
import Bhr from "./Allword/Biomedical Health Research.pdf";
import PremTermination from "./Allword/8PrematureTermination.pdf";
import HumGen from "./Allword/HumanGenetics.pdf";
import SocBeh from "./Allword/SocioBehavioural.pdf";
import FinRepForm from "./Allword/FinalReportFormat.pdf";
import ForInvest from "./Allword/ForInvestigators.pdf";
import ProjExt from "./Allword/15Project_extension.pdf";
import Irmr from "./Allword/Initial_Review_Multicentric_Research.pdf";

const documents = [
  { title: "1.	Expedited Review", url: ExpRev },
  {
    title: "2.	Exemption from Review",
    url: ExempRev,
  },
  {
    title: "3.	Continuing Review / Annual report format ",
    url: ContRevARF,
  },
  {
    title: "4.	Application/Notification form for Amendments",
    url: Amend,
  },
  {
    title: "5.	Protocol Violation/Deviation Reporting form (Reporting by case)",
    url: ProtViol,
  },
  {
    title:
      "6.	Serious Adverse Event Reporting Format (Biomedical Health Research)",
    url: Bhr,
  },
  {
    title: "7.	Premature Termination/Suspension/ Discontinuation Report Format",
    url: PremTermination,
  },
  {
    title: "8.	Human Genetics Testing Research",
    url: HumGen,
  },
  {
    title: "9.	Socio-Behavioural and Public Health Research",
    url: SocBeh,
  },
  {
    title: "10.	Study completion/Final report format",
    url: FinRepForm,
  },
  {
    title: "11.	Format for Curriculum Vitae for Investigators",
    url: ForInvest,
  },
  {
    title: "12.	Format for Project extension",
    url: ProjExt,
  },
  {
    title: "13.	Initial Review Form for Multicentric Research",
    url: Irmr,
  },
];

const CheckList = () => {
  const openPdf = (doc) => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = "download.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="document-table">
      <h2 className="table-heading">ANNEXURES</h2>
      <table>
        <thead>
          <tr>
            <th className="document-title-header">Document Title</th>
            <th className="download-header">Download</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="document-row">
              <td className="document-title">{doc.title}</td>
              <td className="document-download-cell">
                <button
                  className="document-download-button"
                  onClick={() => openPdf(doc.url)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CheckList;
