import React from "react";
import "./engstories.css";

function Engstories() {
  return (
    <div className="engstmain-container">
      <h3 className="engsth">STORIES OF CHANGE THROUGH ENGLISH CLASSES</h3>
      <div className="engstcard-container">
        <div className="engstcard">
          <div className="engstcard-text">
            <p
              className="engstdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ 32-year-old Deepali is a housewife, who is very passionate about
                  her studies, but because of her early marriage, she was not able
                  to continue her studies. She joined Waves English classes when she
                  was pregnant, and during the three months of our English course,
                  she delivered a beautiful child in between. The most astonishing
                  and inspiring part was that she did not miss the classes and
                  covered the missed topics. Her passion for learning is what makes
                  her story inspiring. She is a true learner, and Waves helped her
                  get a tutor for English so that her passion could live on.
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="engstcard-container">
        <div className="engstcard">
          <div className="engstcard-text">
            <p
              className="engstdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ We met Usha, who is a housekeeper. She is a keen learner, but
              she could not learn and study because of her family and started
              working at a very early age. She wants her daughter to educate
              herself as much as she can, but her family doesn't support her. We
              found Usha and offered her daughter the English course. She and
              her daughter both learned together and were excellent students who
              were competing with each other. This mother-daughter duo
              encouraged and inspired us to learn anything at any age.
              <br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="engstcard-container">
        <div className="engstcard">
          <div className="engstcard-text">
            <p
              className="engstdata"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              ✏ 26-year-old Himanshi works as a receptionist at a part time job.
              She wants to apply for the government position but English has
              been her barrier from a very long period of time. Women like
              Himanshi who get their education in regional language usually face
              the same issue. Through our english classes helps women to achieve
              their dreams.
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Engstories;
