import React from "react";
import "./download.css";
import Wwet_1 from "./Allpdf/WWET_SOP_1.pdf";
import Wwet_2 from "./Allpdf/WWET_SOP_2.pdf";
import Wwet_3 from "./Allpdf/WWET_SOP_3.pdf";

const documents = [
  { title: "Waves_IEC_SOP_1", url: Wwet_1 },
  { title: "Waves_IEC_SOP_2", url: Wwet_2 },
  { title: "Waves_IEC_SOP_3", url: Wwet_3 },
];

const DocumentList = () => {
  const openPdf = (doc) => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = "download.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="document-table">
      <h2 className="table-heading">SOPs</h2>
      <table>
        <thead>
          <tr>
            <th className="document-title-header">Document Title</th>
            <th className="download-header">Download</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="document-row">
              <td className="document-title">{doc.title}</td>
              <td className="document-download-cell">
                <button
                  className="document-download-button"
                  onClick={() => openPdf(doc.url)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentList;
