import React from "react";
import "./aids.css";
import Ahmain from "./images1/Ahmain.jpg";
import AH2 from "./images1/AH2.jpg";
import AH3 from "./images1/AH3.jpg";

function Aids() {
  return (
    <div className="main-container">
      <h3 className="ahd">AIDS/HIV</h3>
      <div className="ahcard-container">
        <div className="ahcard">
          <img src={Ahmain} alt="example" className="ahcard-img" />
        </div>
        <div className="ahcard">
          <div className="ahcard-text">
            <p
              className="data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              Waves Women Empowerment has organised an AIDS/HIV awareness
              programme workshop on the occasion of International Women's Day.
              The programme was conducted from March 1 to March 7, 2023. Through
              this, our mission was to create and spread awareness among the
              pregnant women's population.<br></br>
              <br></br>
              Waves representatives visited the gynaecology wing of a government
              hospital for the week, where they interviewed pregnant women who
              were bound for HIV testing as per protocol. The team conducted
              interviews with the patients before and after the counselling to
              get both pre- and post-awareness results for their interview
              questions.<br></br>
            </p>
          </div>
        </div>
      </div>

      <div className="ahcard-container">
        <div className="ahcard">
          <div className="ahcard-text">
            <p
              className="data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              The interview aimed at ascertaining the level of awareness among
              the women regarding HIV/AIDS on parameters like causes of HIV,
              ways of prevention, treatment of HIV, attitude towards one having
              HIV, etc.
              <br></br>
              <br></br>
              The doctor explained how HIV spreads: Reusing syringes, using
              blood from a blood bank without any assurance that the blood is
              HIV-negative, Engaging in sexual intercourse with an individual
              with HIV without using contraceptives Transfer during pregnancy
              from HIV-positive parent to child.
            </p>
          </div>
        </div>
        <div className="ahcard">
          <img src={AH2} alt="example" className="ahcard-img" />
        </div>
      </div>

      <div className="ahcard-container">
        <div className="ahcard">
          <img src={AH3} alt="example" className="ahcard-img" />
        </div>
        <div className="ahcard">
          <div className="ahcard-text">
            <p
              className="data"
              style={{ textIndent: "40px", textAlign: "justify" }}
            >
              There were group and individual counselling sessions held by the
              doctors at the HIV counselling and testing centre for women. The
              counsellor stressed the fact that HIV is not contagious in the way
              a cold, cough, or COVID-19 may be, i.e., it doesn’t require
              complete isolation of the patient or quarantining in that sense.
              <br></br>
              <br></br> Common misconceptions about the disease were clarified,
              and an effort was made to encourage the patients to memorise the
              causes and methods of prevention as well as to encourage them to
              spread correct information to their family and friends.
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aids;
