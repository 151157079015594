import Navbar from "./components/Navbar";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Partners from "./components/p";
import AboutUs from "./components/fabtus";


import HomePage from "./components/Homepage";
import Workshop from "./components/workshop";
import Aids from "./components/aids";
import Meditation from "./components/meditation";
import Ycmp from "./components/ycmp";
import Graphalogy from "./components/graphial.jsx";
import FacialYoga from "./components/facialYoga";
import Financial from "./components/financial.jsx";
import SwasthaMatrutva from"./components/SWASTHAMATRUTVA";
import Webinar from "./components/webinar";
import Yoga from "./components/yoga";
import Omicron from "./components/omicron";
import Stress from "./components/stress";
import Events from "./components/events";
import Drawing from "./components/drawing";
import Survey from "./components/survey";
import Projects from "./components/projects";
import English from "./components/english";
import Yoga1 from "./components/yoga1";
import Yoga2 from "./components/yoga2";
import Storieschange from "./components/stories";
import Engstories from "./components/engstories";
import Ygcmpstories from "./components/ygcmpstories";
import Medistories from "./components/medistories";
import BrdTable from "./components/boardmember";
import IECAbout from "./components/IECabout";
import Address from "./components/address";
import LegalSupport from "./components/legalSupport";
import Counselling from "./components/counselling";
import Medical from "./components/medical";
import Fitness from "./components/fitness";
import Press from "./components/press";
import Awards from "./components/awards";
import Mediacov from "./components/mediacoverage";
import AnnualReport from "./components/annualrpt";
import DwnloadPage from "./components/dwnpage";
import CertList from "./components/cert";
import Intern from "./components/Intern";
import Volunteer from "./components/Volunteer";
import Blog from "./components/Blog";
import DonatePage from "./components/donate";

function App() {
  return (
    <div style={{ maxWidth: "100vw", overflowX: "hidden" }}>
      <Navbar />

      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/fabtus" element={<AboutUs />} />
        <Route path="/mediacoverage" element={<Mediacov />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/press" element={<Press />} />
        <Route path="/annualrpt" element={<AnnualReport />} />

        <Route path="/projects" element={<Projects />} />
        <Route path="/english" element={<English />} />
        <Route path="/yoga1" element={<Yoga1 />} />
        <Route path="/yoga2" element={<Yoga2 />} />

        <Route path="/workshop" element={<Workshop />} />
        <Route path="/aids" element={<Aids />} />
        <Route path="/meditation" element={<Meditation />} />
        <Route path="/ycmp" element={<Ycmp />} />
        <Route path="/facialYoga" element={<FacialYoga />} />
        <Route path="/graphial" element={<Graphalogy/>} />
        <Route path="/Swasthamatrutva" element={<SwasthaMatrutva/>} />
        <Route path="/financial" element={<Financial/>} />

        <Route path="/webinar" element={<Webinar />} />
        <Route path="/yoga" element={<Yoga />} />
        <Route path="/omicron" element={<Omicron />} />
        <Route path="/stress" element={<Stress />} />

        <Route path="/IECabout" element={<IECAbout />} />
        <Route path="/dwnpage" element={<DwnloadPage />} />
        <Route path="/boardmember" element={<BrdTable />} />
        <Route path="/cert" element={<CertList />} />
        <Route path="/blog" element={<Blog />} />

        <Route path="/address" element={<Address />} />
        <Route path="/legalSupport" element={<LegalSupport />} />
        <Route path="/counselling" element={<Counselling />} />
        <Route path="/medical" element={<Medical />} />
        <Route path="/fitness" element={<Fitness />} />

        <Route path="/events" element={<Events />} />
        <Route path="/drawing" element={<Drawing />} />
        <Route path="/survey" element={<Survey />} />

        <Route path="/stories" element={<Storieschange />} />
        <Route path="/engstories" element={<Engstories />} />
        <Route path="/ygcmpstories" element={<Ygcmpstories />} />
        <Route path="/medistories" element={<Medistories />} />

        <Route path="/intern" element={<Intern />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/partner" element={<Partners />} />
        <Route path="/donate" element={<DonatePage />} />
        <Route path="*" element={<>Not Found</>} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
