import React from "react";
import "./download.css";
import Icmr_NEG from "./Allpdf/ICMR_National_Ethical_Guidelines.pdf";
import Icmr_2db from "./Allpdf/ICMR_GuidelinesType2diabetes2018_0.pdf";
import Ec_c19 from "./Allpdf/EC_Guidance_COVID19_06052020.pdf";
import Gsr from "./Allpdf/gsr227e.pdf";
import AI_eth from "./Allpdf/Ethical_Guidelines_AI_Healthcare_2023.pdf";

const documents = [
  {
    title: "ICMR National Ethical Guidelines",
    url: Icmr_NEG,
  },
  {
    title: "ICMR Diabetes Guidelines",
    url: Icmr_2db,
  },
  {
    title: "ICMR AI Ethical Guidelines",
    url: AI_eth,
  },
  {
    title: "EC Covid19 Guidelines",
    url: Ec_c19,
  },
  { title: "gsr227e", url: Gsr },

];

const Guidelines = () => {
  const openPdf = (doc) => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = "download.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <div className="document-table">
      <h2 className="table-heading">GUIDELINES</h2>
      <table>
        <thead>
          <tr>
            <th className="document-title-header">Document Title</th>
            <th className="download-header">Download</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="document-row">
              <td className="document-title">{doc.title}</td>
              <td className="document-download-cell">
                <button
                  className="document-download-button"
                  onClick={() => openPdf(doc.url)}
                >
                  Download
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Guidelines;
