import React from "react";
import { Link } from "react-router-dom";
import "./stories.css";
import StorEng from "./images1/StorEng.jpg";
import StorYoga from "./images1/StorYoga.jpg";
import StorMedi from "./images1/StorMedi.jpg";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="stcard">
      <img src={imageSrc} alt={title} className="stcard-image" />
      <h2 className="stcard-title">{title}</h2>
      <Link to={linkTo} className="stcard-link">
        <button className="stcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Storieschange = () => {
  return (
    <div className="stcards-container">
      <h1 className="storhead">STORIES OF CHANGE</h1>
      <div className="strow">
        <Card
          imageSrc={StorEng}
          title="ENGLISH CLASSES"
          buttonText="Read More"
          linkTo="/engstories"
        />
        <Card
          imageSrc={StorYoga}
          title="YOGA CAMP"
          buttonText="Read More"
          linkTo="/ygcmpstories"
        />
        <Card
          imageSrc={StorMedi}
          title="MEDITATION"
          buttonText="Read More"
          linkTo="/medistories"
        />
      </div>
      {/* <div className="wrow">
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 3"
          buttonText="Read More"
          linkTo="/path/to/page3"
        />
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 4"
          buttonText="Read More"
          linkTo="/path/to/page4"
        />
        <Card
          imageSrc="https://via.placeholder.com/150"
          title="Card 3"
          buttonText="Read More"
          linkTo="/path/to/page3"
        />
      </div> */}
    </div>
  );
};

export default Storieschange;
