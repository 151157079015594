import React from "react";
import "./review2.css";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function ReviewSlider2() {
  return (
    <MDBContainer className="my-5">
      <div
        className="rehead"
        style={{ textAlign: "center", fontSize: "3rem", paddingTop: "1rem" }}
      >
        What People Say About Us
      </div>
      <MDBCarousel showControls dark>
        <MDBCarouselInner>
          <MDBCarouselItem className="active text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  "Being a female journalist makes me more responsible for
                  knowing my own rights and educating others through my work."
                  As the name implies, the Waves Women Empowerment organisation
                  works towards similar goals. A significant observation about
                  this organisation is that their workers foresee what our
                  society needs to change; they have a very concrete answer to
                  that, which impressed me, and I am convinced that their work
                  will have the same positive impact in society.
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3"> - Neeshu</h5>
                <br></br>
                <br></br>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    Waves Women Empowerment Trust For the upliftment of poor,
                    destitute, weak women, for their enthusiasm and for their
                    future, which works in public interest from time to time,
                    the trust is less praised for this, the trust has reached a
                    high level in such a short time. The credit for all this
                    goes to the personnel and support personnel who run this
                    trust, with whose efforts this trust has reached this point
                    today. This trust has become the voice of women, for the
                    upliftment of women through electronic media and print
                    media. Keeps getting information about the works being done
                    for.
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Ravi Kumar Tiwari</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    The Waves Women foundation has taken up an amazing
                    initiative of providing and empowering women from different
                    strata of the society and I was humbled to be a part of this
                    foundation. It gave me the opportunity to interact with a
                    wide diaspora of women who are filled with immense zeal and
                    passion for learning.It allowed me to enhance my teaching
                    abilities and in return helped me gain more experience,
                    introducing me to a new and exciting field of teaching.
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Diya Narayan</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>

          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    I am a housewife who always wanted to study and became a
                    lawyer but because of an early marriage I was unable to do
                    it. With waves' English-speaking programme, I was able to
                    make the initial step towards my goals. At the start of the
                    classes I was six months pregnant and at the end of classes
                    I gave birth to my child but with a short break I continued
                    taking classes as I didn't want to lose this golden
                    opportunity. I'm incredibly appreciative to Waves Women
                    Empowerment for giving me this chance to learn.
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Deepali</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>
          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    Waves, is a place where a person can get a space to grow and
                    learn from very supporting like-minded mentors who are
                    working exceptionally hard for women empowerment. I have
                    been working here with the team since a very long period
                    now. Here, as an individual especially as a female I have
                    learned a lot of things which women in general do not know
                    about themselves like valuing herself, loving herself which
                    I feel is one of the most important thing. i enjoy working
                    here.
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Neha</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>
          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    The Waves Women Empowerment team is so lovely and inspiring
                    to work with. I learnt a lot over the last 3 months of
                    working with the team at waves. Their honesty and commitment
                    towards women empowerment is commendable and very
                    motivational.They engage in various activities from raising
                    awareness to conducting workshops on multiple topics
                    relevant for women's upliftment and well being.Lots of love
                    and all the very best to the team 🤍
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Ragini</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>
          <MDBCarouselItem className="text-center">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol lg="8">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p className="text-muted">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  <i>
                    I work here as a Graphic Designer under Social Media
                    Management. Waves Women Empowerment has been valuable and
                    inspiring for me. Although, in the beginning i thought it
                    would be very tedious but on the contrary, the people are
                    friendly and understanding. Special thanks to Neha Bhatia,
                    my head who was very sweet and kind throughout and was
                    always up for helping with any sort of problem.
                  </i>
                </p>
                <br></br>
                <br></br>
                <h5 className="mb-3">- Mansi</h5>
              </MDBCol>
            </MDBRow>
          </MDBCarouselItem>
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer>
  );
}
