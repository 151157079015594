import React from "react";
import "./merchandise.css";
import prod1 from "./images1/prod1.jpg";
import prod2 from "./images1/prod2.jpg";
import prod4 from "./images1/prod4.jpg";

const Card = ({ imageSrc, title, price, des }) => {
  return (
    <div className="mrccard">
      <img src={imageSrc} alt={title} className="mrccard-image" />
      <h2 className="mrccard-title">{title}</h2>
      <p className="mrccard-price">{price}</p>
      <p className="mrccard-des">{des}</p>
    </div>
  );
};

const Merchandise = () => {
  return (
    <div className="mrccards-container">
      <h1>OUR PRODUCTS</h1>
      <span className="text-center">
        The money raised from the sale of these articles will only be used to
        contribute to the cause. Support us. <br />
        <b style={{ fontSize: "25px" }}>To order- Contact on 9911179893</b>
      </span>
      <div className="mrcrow">
        <Card
          imageSrc={prod1}
          title="BE KIND T-SHIRTS"
          price="Rs 400/-"
          des="The money raised from the sale of these articles will only be used to contribute to the cause.   
          Support US IN SEEKING CHANGE!"
        />
        <Card
          imageSrc={prod4}
          title="MUG"
          price="Rs 250/-"
          des="The money raised from the sale of these articles will only be used to contribute to the cause. 
          Support US IN SEEKING CHANGE!"
        />
        <Card
          imageSrc={prod2}
          title="BE KIND T-SHIRTS/PAINTED WITH LOVE "
          price="Rs 400/-"
          des="The money raised from the sale of these articles will only be used to contribute to the cause. 
          Support US IN SEEKING CHANGE!"
        />
      </div>
    </div>
  );
};

export default Merchandise;
