import React from "react";
import "./contactfrm.css";

const Contactus = () => {
  return (
    <>
      <div className="containerfrm">
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <i className="fas fa-map-marker-alt" />
              <div className="topic">Address</div>
              <div className="text-one">
                9/350A, Gali.no: 9, Lalita Park, Laxmi Nagar
              </div>
              <div className="text-two">Delhi-110092 </div>
            </div>
            <div className="phone details">
              <i className="fas fa-phone-alt" />
              <div className="topic">Phone</div>
              <div className="text-one">+91-9667715007</div>
              <div className="text-two">+91-9911179893</div>
            </div>
            <div className="email details">
              <i className="fas fa-envelope" />
              <div className="topic">Email</div>
              <div className="text-one">waveswomentrust@gmail.com</div>
              <div className="text-two">waveswomenempower@gmail.com</div>
              <div className="text-one">waves111@waveswomen.org</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">
              <h2>Connect with us</h2>
            </div>
            <p style={{ color: "#ffffff" }}>
              For any grievance, suggestions and queries kindly write to us.
            </p>
            <form action="#">
              <div className="input-box">
                <input type="text" placeholder="Enter your name" />
              </div>
              <div className="input-box">
                <input type="text" placeholder="Enter your email" />
              </div>
              <div className="input-box message-box">
                <textarea rows="10" placeholder="Enter your Message" />
              </div>
              <div className="button">
                <input type="button" defaultValue="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
