import React from "react";
import { Link } from "react-router-dom";
import "./awards.css";

const Card = ({ imageSrc, title, buttonText, linkTo }) => {
  return (
    <div className="awcard">
      <h2 className="awcard-title">{title}</h2>
      <Link to={linkTo} className="awcard-link">
        <button className="awcard-button">{buttonText}</button>
      </Link>
    </div>
  );
};

const Awards = () => {
  return (
    <div className="awcards-container">
      <h1>AWARDS AND RECOGNITION</h1>
      <div className="awrow">
        <Card
          title=" नई दिल्ली में महारथी सम्मान समारोह आयोजित "
          buttonText="Read More"
          linkTo="https://www.activenewsindia.com/news/430"
        />
      </div>
    </div>
  );
};

export default Awards;
